export const PLACEHOLDER = {
	PROPERTY_ID: '****PROPERTY_ID****',
	ID: '****ID****',
	PACKAGE_ID: '****PACKAGE_ID****',
	FORM_ID: '****FORM_ID****',
};
const BASE_URL = '/kma/api/';
const BASE_URL_WITH_PROPERTY =
	BASE_URL + 'property/' + PLACEHOLDER.PROPERTY_ID + '/';

export const API = {
	LOCALIZATION: {
		FETCH_ALL_LANGUAGES: BASE_URL + 'resource/localization/all',
	},
	FOLDERS: {
		FETCH: BASE_URL_WITH_PROPERTY + 'folders',
		CREATE: BASE_URL_WITH_PROPERTY + 'folders',
		RENAME: BASE_URL_WITH_PROPERTY + 'folders/' + PLACEHOLDER.ID,
		DELETE: BASE_URL_WITH_PROPERTY + 'folders/' + PLACEHOLDER.ID,
	},
	FORMS: {
		MOVE: BASE_URL_WITH_PROPERTY + 'folders/move',
		GET_FORMS_BY_FOLDER_ID:
			BASE_URL_WITH_PROPERTY + 'folders/' + PLACEHOLDER.ID,
		GET_UNDELETED_FORMS: BASE_URL_WITH_PROPERTY + 'forms',
        REFRESH_CONFIG: BASE_URL_WITH_PROPERTY + 'refresh/',
        REFRESH_STATUS: BASE_URL_WITH_PROPERTY + 'refresh/status/',
		GET_FORM_TRIGGERING: BASE_URL_WITH_PROPERTY + 'forms',
	},
	DRAFTS: {
		GET_UNDELETED_DRAFTS: BASE_URL_WITH_PROPERTY + 'forms/drafts',
	},
	APP_RATINGS: {
		BASE: `${BASE_URL_WITH_PROPERTY}appRating`,
		THEME: `${BASE_URL_WITH_PROPERTY}themes`,
		DEFAULT_THEME_DATA: `${BASE_URL_WITH_PROPERTY}appRating/defaultThemesData`,
	},
	MOBILE_PREVIEW_DETAILS: {
		FETCH: `${BASE_URL_WITH_PROPERTY}mobile/v2/previewApp/shortTermToken`,
	},
	RULES: {
		FETCH: `${BASE_URL_WITH_PROPERTY}rules`,
		FETCH_EXTENDED: `${BASE_URL_WITH_PROPERTY}rules?extended=true`,
	},
	ENV_CONFIG: {
		FETCH: BASE_URL + 'envConfig',
	},
	AUTH: {
		LOGIN: BASE_URL + 'security/doLogin',
		FORGOT_PASSWORD: BASE_URL + 'security/forgotPassword',
	},
	PROFILE: {
		FETCH: BASE_URL_WITH_PROPERTY + 'profile',
	},
	PROPERTIES: {
		FETCH: BASE_URL + 'account/properties',
	},
	PACKAGES: {
		FETCH: BASE_URL_WITH_PROPERTY + 'dhh/packages',
		GENERATE: BASE_URL_WITH_PROPERTY + 'dhh/packages',
		STATUS:
			BASE_URL_WITH_PROPERTY +
			'dhh/packages/' +
			PLACEHOLDER.PACKAGE_ID +
			'/status',
		DOWNLOAD: BASE_URL_WITH_PROPERTY + 'dhh/packages/' + PLACEHOLDER.PACKAGE_ID,
		SET_CONFIRMATION:
			BASE_URL_WITH_PROPERTY +
			'dhh/packages/' +
			PLACEHOLDER.PACKAGE_ID +
			'/confirmDeployment',
	},
	RESOURCES: {
		FETCH: `${BASE_URL_WITH_PROPERTY}resources`,
		UPLOAD: `${BASE_URL_WITH_PROPERTY}resources`,
		DELETE: `${BASE_URL_WITH_PROPERTY}resources/${PLACEHOLDER.ID}`,
	},
	RULE_ENGINE: {
		GET_AVAILABLE_RULES: `${BASE_URL_WITH_PROPERTY}forms/${PLACEHOLDER.FORM_ID}/availableRules?filterByType=customParam`,
		GET_FORM_GENERIC_RULE:`${BASE_URL_WITH_PROPERTY}forms/${PLACEHOLDER.FORM_ID}/genericRule`,
		GET_OCQ_RULES: `${BASE_URL_WITH_PROPERTY}omni-channel/rules`
	},
	ENABLEMENT: {
		UPGRADE: `${BASE_URL_WITH_PROPERTY}upgrade`,
	},
	DOMAINS: {
		FETCH: `${BASE_URL}property/${PLACEHOLDER.PROPERTY_ID}/allowedDomains`,
		SAVE: `${BASE_URL}property/${PLACEHOLDER.PROPERTY_ID}/allowedDomains`,
	},
	THEME: {
		GET_THEME: `${BASE_URL_WITH_PROPERTY}/themes`,
	}
};