import {
  FETCH_APP_RATING_RULES,
  FETCH_APP_RATING_RULES_BY_APP_ID
} from '../actions/action-types';
import { setAppRatingRules } from '../actions/appRatingRules.actions';
import { takeLatest, put } from 'redux-saga/effects';
import HttpManager from '../../services/HttpManager';
import { API } from '../../common/api';

function* fetchAppRatingRules() {
  const URL = `${API.APP_RATINGS.BASE}/availableRules?filterByType=customParam`;
  try {
    const appRatingRulesList = yield HttpManager.get(URL);
    yield put(setAppRatingRules(appRatingRulesList));
  } catch(e) {
    console.error('an error occure while trying to fetch the list of app ratings rules');
  }
}

function* fetchAppRatingRulesByAppId({ payload }) {
  const { appRatingId } = payload;
  const appRatingRulesURL = `${API.APP_RATINGS.BASE}/${appRatingId}/availableRules?filterByType=customParam`;
  try {
    const appRatingRulesList = yield HttpManager.get(appRatingRulesURL);
    yield put(setAppRatingRules(appRatingRulesList));
  } catch(e) {
    console.error(`an error occure while trying to fetch the list of app ratings rules for app rating id ${appRatingId}`);
  }
}

export default function* () {
  yield takeLatest(FETCH_APP_RATING_RULES_BY_APP_ID, fetchAppRatingRulesByAppId);
  yield takeLatest(FETCH_APP_RATING_RULES, fetchAppRatingRules);
}