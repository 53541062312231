import { SET_PROPERTY, GET_PROPERTIES, SET_PROPERTIES } from './action-types';

export function getProperties() {
  return {
    type: GET_PROPERTIES,
  };
}

export function setProperties(properties) {
  return {
    type: SET_PROPERTIES,
    payload: { properties },
  };
}

export function setProperty(property) {
  return {
    type: SET_PROPERTY,
    payload: { property },
  };
}