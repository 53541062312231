import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.localization, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_LOCALIZATION_FORM_ID:
      const { formId } = action.payload;
      return { ...state, formId };

    case ACTION_TYPES.SET_MODAL_LOCALIZATION_VERSIONS:
      /** Used by opening 'Translation' in the context-menu,
       *  or Toggling published/draft from withing the 'Translations'.
       *  Payload always has version, and at least another id.
       */
      const { publishedId, draftId, version, isWithToggle } = action.payload;
      let currentShownId;
      if (version === 'published') {
        currentShownId = publishedId;
      } else if (version === 'draft') {
        currentShownId = draftId;
      }
      return { ...state, version, formId: currentShownId, publishedId: publishedId, draftId: draftId, isWithToggle: isWithToggle };

    case ACTION_TYPES.TOGGLE_FORM_MODE:
      const { formMode } = action.payload;
      return { ...state,
        version: formMode,
        formId: formMode === 'published' ? state.publishedId : state.draftId,
      };

    case ACTION_TYPES.SET_ALL_LANGUAGES:
      const { allLanguages } = action.payload;
      return { ...state, allLanguages };

    case ACTION_TYPES.SET_IMPORT_FILE_NAME:
      const { fileName } = action.payload;
      return { ...state, fileName };

    case ACTION_TYPES.SET_FORM_LOCALIZATION_SETTINGS:
      const { formLocalizationSettings } = action.payload;
      return { ...state, formLocalizationSettings };

    case ACTION_TYPES.SET_AVAILABLE_LAMNGUAGES:
      const { languages } = action.payload;
      return { ...state, availableLanguages: languages };

    case ACTION_TYPES.APPEND_TO_AVAILABLE_LANGUAGES:
      return { ...state, availableLanguages: state.availableLanguages.concat(action.payload) };

    case ACTION_TYPES.DELETE_FROM_AVAILABLE_LANGUAGES:
      const { languageCode } = action.payload;
      const filteredAvailableLanguages = state
        .availableLanguages
        .filter((lang) => lang.label !== languageCode
        );
      return { ...state, availableLanguages: filteredAvailableLanguages };

    case ACTION_TYPES.SET_LOCALIZATION_ACTIONS:
      const { onLanguageSelect, defaultLang, filterer } = action.payload;
      return { ...state, onLanguageSelect, defaultLanguage: defaultLang, filterer };

    case ACTION_TYPES.SET_USE_CUSTOM_PARAM:
      const { useCustomParam } = action.payload;
      return { ...state, formLocalizationSettings: { ...state.formLocalizationSettings, useCustomParam } };

    case ACTION_TYPES.SET_DEFAULT_LANGUAGE:
      const { defaultLanguage } = action.payload;
      return { ...state, formLocalizationSettings: { ...state.formLocalizationSettings, defaultLanguage } };

    case ACTION_TYPES.SET_CUSTOM_PARAM_TO_USE:
      const { customParam = null } = action.payload;
      return { ...state, formLocalizationSettings: { ...state.formLocalizationSettings, customParam } };

    case ACTION_TYPES.RESET_LOCALIZATION_STATE:
      return { ...initialState.localization, allLanguages: state.allLanguages };

    default:
      return state;
  }
}
