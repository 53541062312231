import { initialState } from '../store/initial-state';
import { LOGOUT, SET_PROPERTY } from '../actions/action-types';

export default function(state = initialState.property, action) {
  switch (action.type) {
    case SET_PROPERTY:
      const { property } = action.payload;
      return { ...state, ...property };

    case LOGOUT:
      return initialState.property;
      
    default:
      return state;
  }
}