import {
  APPEND_APP_RATING,
  SET_APP_RATING_LIST,
  SET_APP_RATING_PUBLISHED_STATE,
  SET_APP_RATING_LOCKED_STATE,
  REMOVE_APP_RATING_FROM_STATE,
  ENRICH_APP_RATING,
  SET_APP_RATING,
  SET_APP_RATING_DARK_MODE_STATE
} from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.appRatings, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_APP_RATING_LIST:
      return payload;
    case SET_APP_RATING:
      return state.map((appRating) => appRating.id === payload.id ? payload.data : appRating);
    case APPEND_APP_RATING:
      return [...state, action.payload];
    case ENRICH_APP_RATING:
      return state.map((appRating) => appRating.id === payload.id ? { ...appRating, triggers: payload.data.triggers || [] , mobileThemes: payload.data.mobileThemes || null } : appRating);
    case SET_APP_RATING_PUBLISHED_STATE:
      return state.map((appRating) => appRating.id === payload.id ? { ...appRating, published: payload.newState } : appRating);
    case SET_APP_RATING_LOCKED_STATE:
      return state.map((appRating) => appRating.id === payload.id ? { ...appRating, isLocked: payload.newState } : appRating);
    case REMOVE_APP_RATING_FROM_STATE: 
      return state.filter((item) => item.id !== payload.id);
    case SET_APP_RATING_DARK_MODE_STATE:
      return state.map((appRating) => appRating.id === payload.id ? { ...appRating, isDarkModeEnabled: payload.newState } : appRating);
    default:
      return state;
  }
}