import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.app, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_HELP_CENTER_LINKS:
      return { ...state, helpCenterLinks: action.payload };

    case ACTION_TYPES.SET_PROVISIONS:
      return { ...state, provisions: action.payload };

    case ACTION_TYPES.SET_INBOX_STATE:
      return { ...state, inboxState: action.payload };

    case ACTION_TYPES.SET_APP_PAGE:
      return { ...state, currentPage: action.payload };

    case ACTION_TYPES.SET_UNIQUE_SESSION_REQUEST_ID:
      return { ...state, uniqueSessionRequestID: action.payload };

    case ACTION_TYPES.SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload.message };

    case ACTION_TYPES.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload.message };

    case ACTION_TYPES.SET_FREEZEMODE_MODAL:
      return { ...state,isShowSystemModal:action.isShown };

    case ACTION_TYPES.SET_APP_VERSION_ID:
      return { ...state, versionId:action.payload.versionId };

    case ACTION_TYPES.SET_ENV_CONFIG: {
      const { intercomId, dataCenter } = action.payload.config;
      return { ...state, ...{ intercomId, dataCenter } };
    }

    case ACTION_TYPES.SHOW_SURVEY_UPGRADE_MODAL: {
      return {
        ...state,
        isShowSurveyUpgradeModal: true,
      };
    }

    case ACTION_TYPES.HIDE_SURVEY_UPGRADE_MODAL: {
      return {
        ...state,
        isShowSurveyUpgradeModal: false,
      };
    }

    default:
      return state;
  }
}
