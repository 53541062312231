import { takeLatest, put } from 'redux-saga/effects';
import {
  FETCH_APP_RATINGS,
  TOGGLE_APP_RATING_PUBLISHED_STATE,
  TOGGLE_APP_RATING_LOCKED_STATE,
  DELETE_APP_RATING,
  CREATE_APP_RATING,
  GET_APP_RATING,
  UPDATE_APP_RATING,
  TOGGLE_APP_RATING_DARK_MODE_STATE,
  FETCH_APP_RATING_BANNER_THEME
} from '../actions/action-types';
import {
  setAppRatingsList,
  removeAppRating,
  setAppRatingPublishedState,
  setAppRatingLockedState,
  appendAppRating,
  enrichAppRating,
  setAppRating,
  setAppRatingDarkModeState
} from '../actions/appRating.actions';
import { setSuccessMessage, setErrorMessage } from '../actions/app.actions';
import HttpManager from '../../services/HttpManager';
import { API } from '../../common/api';
import { checkProvision, getAppRatingBannerThemeDefaultData } from '../store/store-helper';
import { PROVISIONS } from '../../common/enums';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';
import { setAppRatingDefualtBannerTheme } from '../actions/theme.action';

function* handleError(errMsg) {
  yield put(setErrorMessage(errMsg));
  console.error(errMsg);
}

function* fetchAppRatings() {
  try {
    const appRatingsList = yield HttpManager.get(API.APP_RATINGS.BASE);
    yield put(setAppRatingsList(appRatingsList));
  } catch(e) {
    yield handleError('an error occure while trying to fetch the list of app ratings.');
  }
}

function* getAppRating({ payload }) {
  const { id, done } = payload;
  try {
    const appRatingData = yield HttpManager.get(`${API.APP_RATINGS.BASE}/${id}`);
    if (checkProvision(PROVISIONS.MOBILE_SDKV2_NATIVE_DARK_MODE)) {
      const theme = yield HttpManager.get(`${API.APP_RATINGS.THEME}/banner_${id}`);
      appRatingData.mobileThemes = theme;
    }
    yield put(enrichAppRating(id, appRatingData));
    done();
  } catch(e) {
    yield handleError(`an error occure while trying to get data for app rating id ${id}`);
  }
}

function* updateAppRating({ payload }) {
  const { id, data } = payload;
  const { triggers, mobileThemes, ...restOfData } = data;
  const [trigger] = triggers;
  checkProvision(PROVISIONS.MOBILE_SDKV2_NATIVE_DARK_MODE) && updateAppRatingTheme(mobileThemes , trigger);
  const appRatingData = {
    mobileThemes: mobileThemes ? [mobileThemes] : [],
    appRatingContract : restOfData ? restOfData : {},
  };
  const URL = `${API.APP_RATINGS.BASE}/${id}`;
  const triggerURL = `${URL}/triggers`;
  try {
    yield HttpManager.post(URL, appRatingData);
    yield HttpManager.post(triggerURL, trigger);
    yield put(setSuccessMessage('App Rating updated successfully'));
    yield put(setAppRating(id, data));
  } catch (e) {
    yield handleError(`an error occure while trying update data for app rating id ${id}`);
  }
}

function* createAppRating({ payload }) {
  try {
    const newAppRating = yield HttpManager.post(API.APP_RATINGS.BASE, payload);
    yield put(setSuccessMessage('App Rating created successfully'));
    yield put(appendAppRating(newAppRating));
  } catch(e) {
    yield handleError('An error occurred while trying to create a new app rating');
  }
}

function* toggleAppRatingPublishedState({ payload }) {
  const { id, newState } = payload;
  const url = `${API.APP_RATINGS.BASE}/${id}/publish/${newState}`;
  try {
    yield HttpManager.post(url, {});
    yield put(setSuccessMessage(`App Rating ${newState ? 'published' : 'unpublished'} successfully`));
    yield put(setAppRatingPublishedState({ id, newState }));
  } catch(e) {
    yield handleError(`an error occure while trying to set app rating id ${id} published state to ${newState}`);
  }
}

function* toggleAppRatingLockedState({ payload }) {
  const { id, newState } = payload;
  const url = `${API.APP_RATINGS.BASE}/${id}/lock/${newState}`;
  try {
    yield HttpManager.post(url, { id });
    yield put(setSuccessMessage(`App Rating ${newState ? 'locked' : 'unlocked'} successfully`));
    yield put(setAppRatingLockedState({ id, newState }));
  } catch(e) {
    yield handleError(`an error occure while trying to set app rating id ${id} locked state to ${newState}`);
  }
}

function* deleteAppRating({ payload }) {
  const { id } = payload;
  const url = `${API.APP_RATINGS.BASE}/${id}`;
  try {
    yield HttpManager.delete(url);
    yield put(setSuccessMessage('App Rating deleted successfully'));
    yield put(removeAppRating(id));
  } catch(e) {
    yield handleError(`an error occurred while trying to remove app rating id ${id}`);
  }
}

function* toggleAppRatingDarkModeState({ payload }) {
  const { id, newState } = payload;
  const isDarkMode = newState;
  const url = `${API.APP_RATINGS.BASE}/${id}/darkMode/`;
  try {
    yield HttpManager.post(url, { isDarkMode });
    yield put(setSuccessMessage(`App Rating Dark Mode ${newState ? 'Enabled' : 'Disabled'}`));
    yield put(setAppRatingDarkModeState({ id, newState }));
  } catch(e) {
    yield handleError(`an error occure while trying to set app rating id ${id} locked state to ${newState}`);
  }
}

function updateAppRatingTheme(mobileThemes , trigger) {
  mobileThemes.themeData.light.palette.core.canvas = getNestedProperty(trigger, 'triggerCustomBannerContract.backgroundColor');
  mobileThemes.themeData.light.palette.action.primary = getNestedProperty(trigger, 'triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonBackgroundColor');
  mobileThemes.themeData.light.palette.action.auxiliary = getNestedProperty(trigger, 'triggerCustomBannerContract.customBannerButtonsSettingsContract.closeButtonColor');
  mobileThemes.themeData.light.palette.typography.headline = getNestedProperty(trigger, 'triggerCustomBannerContract.textColor');
  mobileThemes.themeData.light.palette.typography.action.primary =  getNestedProperty(trigger, 'triggerCustomBannerContract.customBannerButtonsSettingsContract.acceptButtonTextColor');
}

function* fetchAppRatingDefualtBannerTheme() {
  const URL = `${API.APP_RATINGS.DEFAULT_THEME_DATA}`;
  try {
    const bannerTheme = getAppRatingBannerThemeDefaultData();
    if(_.isEmpty(bannerTheme)){
      const themeData = yield HttpManager.get(URL);
      yield put(setAppRatingDefualtBannerTheme(themeData));
    }
  } catch(e) {
    console.error('an error occure while trying to fetch the app ratings defualt banner theme');
  }
}

export default function* () {
  yield takeLatest(FETCH_APP_RATINGS, fetchAppRatings);
  yield takeLatest(TOGGLE_APP_RATING_PUBLISHED_STATE, toggleAppRatingPublishedState);
  yield takeLatest(TOGGLE_APP_RATING_LOCKED_STATE, toggleAppRatingLockedState);
  yield takeLatest(DELETE_APP_RATING, deleteAppRating);
  yield takeLatest(CREATE_APP_RATING, createAppRating);
  yield takeLatest(GET_APP_RATING, getAppRating);
  yield takeLatest(UPDATE_APP_RATING, updateAppRating);
  yield takeLatest(TOGGLE_APP_RATING_DARK_MODE_STATE, toggleAppRatingDarkModeState);
  yield takeLatest(FETCH_APP_RATING_BANNER_THEME ,fetchAppRatingDefualtBannerTheme);
}