import { GET_PROFILE, SET_PROFILE, SET_FREEZE_MODE_STATE } from './action-types';

export function getProfile() {
  return {
    type: GET_PROFILE,
  };
}

export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    payload: { profile },
  };
}

export function setFreezeModeState(state) {
  return {
    type: SET_FREEZE_MODE_STATE,
    payload: { state },
  };
}