export const SORT_ORDER = {
  DESC: 'desc',
  ASC: 'asc'
};

export const FORM_VIEW_TYPES = {
  LIST: 'list',
  GRID: 'grid'
};

export const FORM_ACTION_TYPES = {
  FILTER: 'FILTER'
};

export const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
};

export const JOB_TYPES = {
  DELETE: 'DELETE'
};

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'danger',
  WARNING: 'warning',
};

export const KAMPYLE_IDS = {
  ILLEGAL_ARGUMENT_EXCEPTION: 'illegalArgumentException',
  FOLDER_NAME_ALREADY_EXISTS: 'folderNameAlreadyExists',
  FOLDER_ON_GOING_ACTION: 'folderOngoingAction',
  FOLDER_DOESNT_BELONG_TO_PROPERTY: 'folderDoesntBelongToProperty',
  LOGIN_BAD_CREDENTIALS : 'loginBadCredentials',
  DHH_PACKAGE_IS_BEING_GENERATED: 'DHHPackageIsBeingGenerated',
  INVALID_FILE_TYPE: 'invalidFileType',
  FILE_LARGER_THEN_2000KB: 'fileLargeThen2000KB',
  FILE_NAME_ALREADY_EXISTS: 'fileNameAlreadyExists',
  PACKAGE_NOT_THE_LATEST: 'packageNotTheLatest',
  PACKAGE_NOT_THE_LATEST_NEED_TO_REFRESH: 'packageNotTheLatestNeedToRefresh',
  PACKAGE_NOT_THE_LATEST_NEED_TO_GENERATE_PACKAGE: 'packageNotTheLatestNeedToGeneratePackage',
  PACKAGE_ALREADY_CONFIRMED: 'packageAlreadyConfirmed',
  FREEZE_MODE:'propertyInFreezeMode',
  RESOURCE_DELETED : 'resourceDeleted',
  RESOURCE_IN_USE:'resourceInUse',
  ILLEGAL_FILE_NAME: 'illegalFileName',
  PACKAGE_GENERATION_FAILED: 'packageGenerationFailed',
  FORMS_REFRESH_ALREADY_IN_PROGRESS: 'refreshAlreadyInProgress',
};

export const USER_ROLES = {
  ROLE_NONE: 0,
  ROLE_VIEWER: 1,
  ROLE_USER: 2,
  ROLE_USER_GROUP_MANAGER: 3,
  ROLE_ADMIN: 4,
  ROLE_SUPER_ADMIN: 5,
  ROLE_TOP: 6
};

export const FORM_TRIGGER_TYPES = {
  EMBEDDED: '.EmbeddedContract',
  CODE: '.CodeContract',
  BUTTON: '.ButtonContract',
  INVITE: '.InvitationContract',
  MOBILE: '.MobileInvitationContract'
};

export const FORM_TRIGGER_TYPES_LABELS = {
  INVITE: 'Invite',
  BUTTON: 'Button',
  CODE: 'Code',
  EMBEDDED: 'Embedded'
};

export const IMAGE_FILE_TYPES = {
  PNG: '.png',
  JPG: '.jpg',
  JPEG: '.jpeg',
  GIF: '.gif'
};

export const CONTEXT_MENU_OPTIONS = {
  RENAME: 'Rename',
  DELETE: 'Delete'
};

export const DOCUMENT_EVENTS = {
  MOUSE_UP: 'mouseup',
  SCROLL: 'scroll'
};

export const FOLDER_ACTIONS = {
  DELETE: 'Delete',
  RENAME: 'Rename',
  MOVE: 'Move'
};

export const ANIMATED_DIRECTIONS = {
  CENTER_RIGHT: 'centerRight',
  CENTER_LEFT: 'centerLeft',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM_LEFT: 'bottomLeft'
};

export const POPUP_HOSTS = {
  MEDALLIA: 'medallia',
  SELF: 'mySite'
};

export const FORM_DISPLAYS = {
  LIGHTBOX: 'lightbox',
  POPUP: 'popup',
  ANIMATED: 'animation'
};

export const PROVISIONS = {
  NO_FORM_POPUP: 'noFormPopup',
  ADD_SELF_HOSTED_POPUP: 'addSelfHostedPopUp',
  REUSABLE_RESOURCES_LIBRARY: 'reusableResourcesLibrary',
  DYNAMIC_HYBRID_HOSTING: 'dynamicHybridHosting',
  ENABLE_SELF_SERVICE_V2_MIGRATION : '21dcr6_MPC4144_enable_SelfServiceV2Migration',
  MOBILE_SDKV2_NATIVE_DARK_MODE: '21dcr8_MPC2959_mobileSDKV2NativeDarkMode',
	FORM_V2: '21dcr2_MPC2743_enable_newLiveForm',
  ENABLE_SURVEY_DRAFTS: '23dcr3_MPC5608_enableDraftsSupport',
  MOBILE_SDKV2_OMNI_CHANNEL_QUARANTINE: '22dcr2_mpc5461_mobileSDKV2OCQ'
};

export const REQUEST_HEADERS = {
  X_MD_REQ_ID: 'X-MD-REQ-ID',
  X_XSRF_TOKEN: 'X-XSRF-TOKEN',
  CONTENT_TYPE: 'Content-Type',
  TRACE_PARENT_STRING: 'traceparent'
};

export const RESULT_CODES = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  FORBIDDEN: 'FORBIDDEN',
  UNAUTHORIZED: 'UNAUTHORIZED',
  IN_PROGRESS: 'IN_PROGRESS',
  PARTIAL: 'PARTIAL',
};

export const PROPERTY_TYPES = {
  WEBSITE: 'website'
};

export const PACKAGE_STATUS = {
  GENERATING: 'GENERATING',
  READY: 'READY',
  DOWNLOADED: 'DOWNLOADED',
  DEPLOYED: 'DEPLOYED',
  FAIL: 'FAIL',
};

export const FILE_TYPES_EXTENSIONS = {
  IMAGES: IMAGE_FILE_TYPES,
  ARCHIVES: ['.zip'],
  STYLES: ['.css']
};

export const REUSEABLE_FIELD_MODAL = {
  HELP: 'Help',
  APPLY: 'Apply',
  CANCEL: 'Cancel',
  CLOSE: 'Close'
};

export const REFRESH_SCOPES = {
  ALL: 'ALL',
  TARGETING: 'TARGETING',
  FORMS: 'FORMS',
  MEC: 'MEC',
};

export const Z_INDEX_MAP = {
	BASE : 1,
	FIRST_LAYER : 2,
	SECOND_LAYER : 3,
	THIRD_LAYER : 4,
  MODAL : 9999,
}

export const FORM_STATUS = {
  PUBLISHED : 'Published',
  DRAFT : 'Draft',
  PUBLISHED_WITH_DRAFT : 'Published *'
}

export const ShownMode = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
}
