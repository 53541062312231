import { put, takeLatest } from 'redux-saga/effects';
import { API, PLACEHOLDER } from '../../common/api';
import HttpManager from '../../services/HttpManager';
import { FETCH_AVAILABLE_FORM_RULES, FETCH_FORM_GENERIC_RULE, FETCH_OCQ_RULES } from '../actions/action-types';
import { setAvailableRules, setFormGenericRule, setOCQRules } from '../actions/rule-engine.actions';

function* fetchAllAvailableRulesHandler({ formId }) {
  try {
    const url = API.RULE_ENGINE.GET_AVAILABLE_RULES.replace(
      PLACEHOLDER.FORM_ID,
      formId
    );
    const availableRules = yield HttpManager.get(url);
    yield put(setAvailableRules(availableRules));
  } catch (e) {
    console.error(e);
  }
}

function* fetchGenericRuleByIdHandler({ payload:{ resolvePromise,formId,rejectPromise } }){
  try{
    const url = API.RULE_ENGINE.GET_FORM_GENERIC_RULE.replace(
      PLACEHOLDER.FORM_ID,
      formId
    );
    const formGenericRule = yield HttpManager.get(url);
    yield put(setFormGenericRule(formGenericRule));
    yield resolvePromise && resolvePromise(formGenericRule);
  }catch(e){
    console.error(e);
    rejectPromise(e);
  }
}

function* fetchOCQRules() {
  try {
    const url = API.RULE_ENGINE.GET_OCQ_RULES;
    const OCQRules = yield HttpManager.get(url);
    yield put(setOCQRules(OCQRules));
  } catch (e) {
    console.error('an error accurse while trying to get omni channel rules');
  }
}

export default function* () {
  yield takeLatest(FETCH_AVAILABLE_FORM_RULES, fetchAllAvailableRulesHandler);
  yield takeLatest(FETCH_FORM_GENERIC_RULE,fetchGenericRuleByIdHandler);
  yield takeLatest(FETCH_OCQ_RULES, fetchOCQRules);
}