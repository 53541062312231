// app
export const SET_REUSABLE_FIELDS = 'SET_REUSABLE_FIELDS';
export const SET_CUSTOM_PARAMETERS = 'SET_CUSTOM_PARAMETERS';
export const SET_CUSTOM_PARAMETERS_ACTIONS = 'SET_CUSTOM_PARAMETERS_ACTIONS';
export const SET_ALL_ENGAGEMENTS = 'SET_ALL_ENGAGEMENTS';

export const SET_ALL_FORMS_ENGAGEMENTS = 'SET_ALL_FORMS_ENGAGEMENTS';
export const SET_ALL_APP_RATINGS_ENGAGEMENTS = 'SET_ALL_APP_RATINGS_ENGAGEMENTS';
export const FETCH_ALL_LANGUAGES = 'FETCH_ALL_LANGUAGES';
export const SET_ALL_LANGUAGES = 'SET_ALL_LANGUAGES';
export const SET_LOCALIZATION_ACTIONS = 'SET_LOCALIZATION_ACTIONS';
export const SET_APP_PAGE = 'SET_APP_PAGE';
export const SET_FREEZEMODE_MODAL = 'SET_FREEZEMODE_MODAL';
export const SET_APP_VERSION_ID = 'SET_APP_VERSION_ID';
export const FETCH_ENV_CONFIG = 'FETCH_ENV_CONFIG';
export const SET_ENV_CONFIG = 'SET_ENV_CONFIG';
export const SHOW_SURVEY_UPGRADE_MODAL = 'SHOW_SURVEY_UPGRADE_MODAL';
export const HIDE_SURVEY_UPGRADE_MODAL = 'HIDE_SURVEY_UPGRADE_MODAL';

// property
export const SET_PROPERTY_ID = 'SET_PROPERTY_ID';
export const SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE';
export const SET_PROPERTY_ROLE = 'SET_PROPERTY_ROLE';
// localization
export const SET_LOCALIZATION_FORM_ID = 'SET_LOCALIZATION_FORM_ID';
export const SET_IMPORT_FILE_NAME = 'SET_IMPORT_FILE_NAME';
export const SET_AVAILABLE_LAMNGUAGES = 'SET_AVAILABLE_LAMNGUAGES';
export const SET_FORM_LOCALIZATION_SETTINGS = 'SET_FORM_LOCALIZATION_SETTINGS';
export const SET_USE_CUSTOM_PARAM = 'SET_USE_CUSTOM_PARAM';
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';
export const SET_CUSTOM_PARAM_TO_USE = 'SET_CUSTOM_PARAM_TO_USE';
export const RESET_LOCALIZATION_STATE = 'RESET_LOCALIZATION_STATE';
export const APPEND_TO_AVAILABLE_LANGUAGES = 'APPEND_TO_AVAILABLE_LANGUAGES';
export const DELETE_FROM_AVAILABLE_LANGUAGES = 'DELETE_FROM_AVAILABLE_LANGUAGES';
export const SET_HELP_CENTER_LINKS = 'SET_HELP_CENTER_LINKS';
export const SET_PROVISIONS = 'SET_PROVISIONS';
export const TOGGLE_FORM_MODE = 'TOGGLE_FORM_MODE';
export const SET_MODAL_LOCALIZATION_VERSIONS = 'SET_MODAL_LOCALIZATION_VERSIONS';
// form list view
export const SET_FORM_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_UNDELETED_FORMS = 'SET_UNDELETED_FORMS';
export const REMOVE_FORM = 'REMOVE_FORM';
export const ADD_FORM = 'ADD_FORM';
export const SET_FORM_DRAFT_ID = 'SET_FORM_DRAFT_ID';
export const SET_TRIGGER_TYPE = 'SET_TRIGGER_TYPE';
export const SET_PUBLISH_STATE = 'SET_PUBLISH_STATE';
export const SET_LOCK_STATE = 'SET_LOCK_STATE';
export const SET_FORM_NAME = 'SET_FORM_NAME';
export const SET_FORM_MODIFIED_DATE = 'SET_FORM_MODIFIED_DATE';
export const SET_SORT_OPTIONS = 'SET_SORT_OPTIONS';
export const SET_FORM_SPINNER = 'SET_FORM_SPINNER';
export const SET_INBOX_STATE = 'SET_INBOX_STATE';
export const FILTER_QUERY_STRING = 'FILTER_QUERY_STRING';
export const CHECK_FORM = 'CHECK_FORM';
export const UNCHECK_FORM = 'UNCHECK_FORM';
export const CHECK_ALL_FORMS = 'CHECK_ALL_FORMS';
export const UNCHECK_ALL_FORMS = 'UNCHECK_ALL_FORMS';
export const GET_FORMS_BEFORE_DELETE_FOLDER = 'GET_FORMS_BEFORE_DELETE_FOLDER';
export const SET_FORMS_BEFORE_DELETE_FOLDER = 'SET_FORMS_BEFORE_DELETE_FOLDER';
export const GET_UNDELETED_FORMS = 'GET_UNDELETED_FORMS';
export const SET_DARK_MODE_STATE = 'SET_DARK_MODE_STATE';
export const FETCH_FORM_TRIGGERING_DATA = 'FETCH_FORM_TRIGGERING_DATA';
export const SET_FORM_TRIGGERING_DATA = 'SET_FORM_TRIGGERING_DATA';
export const CHANGE_FORM_TARGETING = 'CHANGE_FORM_TARGETING';
export const SAVE_FORM_TARGETING = 'SAVE_FORM_TARGETING';
// drafts (form list view)
export const GET_UNDELETED_DRAFTS = 'GET_UNDELETED_DRAFTS';
export const SET_FORM_IS_PUBLISHED = 'SET_FORM_IS_PUBLISHED';
// refresh
export const START_REFRESH_POLLING = 'START_REFRESH_POLLING';
export const STOP_REFRESH_POLLING = 'STOP_REFRESH_POLLING';
// folders
export const FETCH_FOLDERS = 'FETCH_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const CREATE_NEW_FOLDER = 'CREATE_NEW_FOLDER';
export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER';
export const MOVE_FORMS_TO_FOLDER = 'MOVE_FORMS_TO_FOLDER';
export const START_POLLING = 'START_POLLING';
export const STOP_POLLING = 'STOP_POLLING';
export const SET_FOLDER_IN_PROCESS = 'SET_FOLDER_IN_PROCESS';
export const END_FOLDERS_PROCESS = 'END_FOLDERS_PROCESS';
export const SET_CUSTOM_NOTICE_MODAL_STATE = 'SET_CUSTOM_NOTICE_MODAL_STATE';
// auth
export const LOGIN = 'LOGIN';
export const LOGIN_FROM_SESSION = 'LOGIN_FROM_SESSION';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CLEAN_LOGIN_ERROR = 'CLEAN_LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_UNIQUE_SESSION_REQUEST_ID = 'SET_UNIQUE_SESSION_REQUEST_ID';
// profile
export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_FREEZE_MODE_STATE = 'SET_FREEZE_MODE_STATE';
// properties
export const GET_PROPERTIES = 'GET_PROPERTIES';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_PROPERTY = 'SET_PROPERTY';
export const UNFREEZE_PROPERTY = 'UNFREEZE_PROPERTY';
// app rating
export const FETCH_APP_RATINGS = 'FETCH_APP_RATINGS';
export const TOGGLE_APP_RATING_PUBLISHED_STATE =
	'TOGGLE_APP_RATING_PUBLISHED_STATE';
export const TOGGLE_APP_RATING_LOCKED_STATE = 'TOGGLE_APP_RATING_LOCKED_STATE';
export const DELETE_APP_RATING = 'DELETE_APP_RATING';

export const SET_APP_RATING_LIST = 'SET_APP_RATING_LIST';
export const REMOVE_APP_RATING_FROM_STATE = 'REMOVE_APP_RATING_FROM_STATE';
export const SET_APP_RATING_PUBLISHED_STATE = 'SET_APP_RATING_PUBLISHED_STATE';
export const SET_APP_RATING_LOCKED_STATE = 'SET_APP_RATING_LOCKED_STATE';
export const CREATE_APP_RATING = 'CREATE_APP_RATING';
export const APPEND_APP_RATING = 'APPEND_APP_RATING';
export const UPDATE_APP_RATING = 'UPDATE_APP_RATING';
export const GET_APP_RATING = 'GET_APP_RATING';
export const ENRICH_APP_RATING = 'ENRICH_APP_RATING';
export const SET_APP_RATING = 'SET_APP_RATING';
export const SET_APP_RATING_DARK_MODE_STATE = 'SET_APP_RATING_DARK_MODE_STATE';
export const TOGGLE_APP_RATING_DARK_MODE_STATE = 'TOGGLE_APP_RATING_DARK_MODE_STATE';
export const FETCH_APP_RATING_BANNER_THEME = 'FETCH_APP_RATING_BANNER_THEME';

// app rating rules
export const SET_APP_RATING_RULES = 'SET_APP_RATING_RULES';
export const FETCH_APP_RATING_RULES = 'FETCH_APP_RATING_RULES';
export const FETCH_APP_RATING_RULES_BY_APP_ID = 'FETCH_APP_RATING_RULES_BY_APP_ID';
export const FETCH_OCQ_RULES = 'FETCH_OCQ_RULES';
export const SET_OCQ_RULES = 'SET_OCQ_RULES';

// mobile preview integration
export const SET_MOBILE_PREVIEW_URL = 'SET_MOBILE_PREVIEW_DETAILS';
export const SET_MOBILE_PREVIEW_MODAL_SHOWN = 'SET_MOBILE_PREVIEW_MODAL_SHOWN';
export const FETCH_MOBILE_PREVIEW_DETAILS = 'FETCH_MOBILE_PREVIEW_DETAILS';

export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

// form preload
export const SET_PRELOADED_FORM_ID = 'SET_PRELOADED_FORM_ID';

// packages
export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const SET_PACKAGES = 'SET_PACKAGES';
export const GENERATE_PACKAGE = 'GENERATE_PACKAGE';
export const START_PACKAGE_POLLING = 'START_PACKAGE_POLLING';
export const STOP_PACKAGE_POLLING = 'STOP_PACKAGE_POLLING';
export const END_PACKAGE_DOWNLOAD = 'END_PACKAGE_DOWNLOAD';
export const TRIGGER_DOWNLOAD_PACKAGE = 'TRIGGER_DOWNLOAD_PACKAGE';
export const CONFIRM_PACKAGE = 'CONFIRM_PACKAGE';

// resources
export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const SET_RESOURCES = 'SET_RESOURCES';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';

//rule-engine
export const SET_FORM_GENERIC_RULE = 'SET_FORM_GENERIC_RULE';
export const FETCH_AVAILABLE_FORM_RULES = 'FETCH_AVAILABLE_FORM_RULES';
export const FETCH_FORM_GENERIC_RULE = 'FETCH_FORM_GENERIC_RULE';
export const SET_AVAILABLE_RULES = 'SET_AVAILABLE_RULES';

// enablement
export const DISABLE_FEATURE = 'DISABLE_FEATURE';
export const ENABLE_FEATURE = 'ENABLE_FEATURE';
export const STOP_FEATURE_STATUS_POLLING = 'STOP_FEATURE_STATUS_POLLING';

//theme
export const SET_APP_RATING_DEFUALT_BANNER_THEME = 'SET_APP_RATING_DEFUALT_BANNER_THEME';
export const GET_THEME_BY_NAME = 'GET_THEME_BY_NAME';
export const SET_THEME_DATA = 'SET_THEME_DATA';