import { delay, put, takeLatest } from 'redux-saga/effects';
import * as ACTION_TYPES from '../actions/action-types';
import * as storeHelper from '../store/store-helper';
import { getPropertyId, getSelectedFolder } from '../store/store-helper';
import { getSingleText, pushNotification } from '../../common/utils';
import { getNotificationByKampyleId } from '../../common/notification-mapper';
import HttpManager from '../../services/HttpManager';
import { API, PLACEHOLDER } from '../../common/api';
import { NOTIFICATION_TYPES, REFRESH_SCOPES, RESULT_CODES, KAMPYLE_IDS } from '../../common/enums';
import { stopRefreshPolling } from '../actions/refresh.actions';
import { getFormsByFolderId } from './forms.saga';
import { getUndeletedForms, setUndeletedForms } from '../actions/form.actions';

function* startStatusPolling(taskId, shouldSilentNotification) {
  const pollingInterval = storeHelper.getPollingInterval();

  while (storeHelper.shouldPollRefresh()) {
    yield fetchFormsRefreshStatus(taskId, shouldSilentNotification);
    yield delay(pollingInterval);
  }
}

function* fetchFormsRefreshStatus(taskId, shouldSilentNotification) {
  
  const response = yield HttpManager.get(API.FORMS.REFRESH_STATUS + taskId);
  
  const isExceptionResult = response.status === RESULT_CODES.FAILURE && response.succeededFormsCount;
  if(isExceptionResult) {
    yield handleError({ errorId: response.kampyleId, errorMessage: response.resultMessage, shouldSilentNotification });
  } else {
    yield handleStatus(response, taskId, shouldSilentNotification);
  }
}

function* handleError({ errorId, errorMessage, shouldSilentNotification, fallbackMessage }) {
  if(shouldSilentNotification) {
    yield put(stopRefreshPolling());
    return;
  }
  const message = errorMessage || getNotificationByKampyleId(errorId, fallbackMessage);
  pushNotification({
    type: NOTIFICATION_TYPES.FAILURE,
    message: message,
  });
  yield put(stopRefreshPolling());
}

function* updateFormsList() {
  const selectedFolder = getSelectedFolder();
  if(selectedFolder){
    const undeletedForms = yield getFormsByFolderId({ folderId: selectedFolder.id });
    yield put(setUndeletedForms(undeletedForms));
  } else {
    yield put(getUndeletedForms());
  }
}

export function* handleStatus(response, taskId, shouldSilentNotification) {
  if(response.status === RESULT_CODES.IN_PROGRESS) {
    return;
  }
  yield put(stopRefreshPolling());
  
  if(shouldSilentNotification) {return;}
  
  const texts = getSingleText('app.pages.forms.refreshForm');
  
  if(response.succeededFormsCount) {
    yield updateFormsList();
  }
  if(response.status === RESULT_CODES.SUCCESS) {
    pushNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${response.succeededFormsCount} ${texts.forms} ${texts.success}`,
    });
  } else {
    const logsUrl = API.FORMS.REFRESH_CONFIG + taskId;
    const link = logsUrl.replace(PLACEHOLDER.PROPERTY_ID, getPropertyId());
    
    if(response.status === RESULT_CODES.PARTIAL) {
      const partialText = texts.partialSuccess.replace('[SUCCEEDED]', response.succeededFormsCount).replace('[FAILED]', response.failedFormsCount);
      pushNotification({
        type: NOTIFICATION_TYPES.WARNING,
        message: partialText,
        link,
      });
    } else {
      // FAILURE
      pushNotification({
        type: NOTIFICATION_TYPES.FAILURE,
        message: `${response.failedFormsCount} ${texts.forms} ${texts.error}`,
        link,
      });
    }
  }
}
  
function* startFormsRefresh({ payload: { allSelectedForms, areAllItemsChecked, refreshScope } }) {
  const propertyType = storeHelper.getPropertyType();
  const isMobile = propertyType !== PropertyTypes.WEBSITE;
  
  const areAnySelected = allSelectedForms && allSelectedForms.length;
  if(!areAnySelected && !isMobile) {
    return yield handleError({ errorMessage: 'No forms were selected!' });
  }
  
  const apiPayload = {
    scope: REFRESH_SCOPES[refreshScope] || REFRESH_SCOPES.ALL,
  };
  if(!areAllItemsChecked) {
    apiPayload.formIds = allSelectedForms.map((x) =>parseInt(x));
  } else if(!isMobile) {
    apiPayload.folderIds = [storeHelper.getSelectedFolder().id];
  }
  try {
    const response = yield HttpManager.post(API.FORMS.REFRESH_CONFIG, apiPayload);
    
    if(response.kampyleId === KAMPYLE_IDS.FORMS_REFRESH_ALREADY_IN_PROGRESS) {
      pushNotification({
        type: NOTIFICATION_TYPES.FAILURE,
        message: getNotificationByKampyleId(KAMPYLE_IDS.FORMS_REFRESH_ALREADY_IN_PROGRESS),
      });
      // Should poll for second user disabling
      yield startStatusPolling(response.resultMessage, true);
      
    } else if(response.resultCode === RESULT_CODES.FAILURE || !response.resultMessage){
      return yield handleError({ errorMessage: !response.resultMessage ? 'No taskId was given!' : '' });
    } else {
      yield startStatusPolling(response.resultMessage);
    }
    
  } catch ({ kampyleId, resultMessage }) {
    const errorObject = {
      errorId: kampyleId,
      errorMessage: resultMessage,
      fallbackMessage: 'An error occurred while trying to refresh forms configuration.',
    };
    return yield handleError(errorObject);
  }
}

export default function* () {
  yield takeLatest(ACTION_TYPES.START_REFRESH_POLLING, startFormsRefresh);
}