import { FETCH_APP_RATING_RULES, FETCH_APP_RATING_RULES_BY_APP_ID, SET_APP_RATING_RULES } from './action-types';

export function fetchAppRatingRulesByAppRatingId(appRatingId) {
  return {
    type: FETCH_APP_RATING_RULES_BY_APP_ID,
    payload: { appRatingId },
  };
}

export function fetchAppRatingRules() {
  return {
    type: FETCH_APP_RATING_RULES,
  };
}

export function setAppRatingRules(appRatingRulesList = []) {
  return {
    type: SET_APP_RATING_RULES,
    payload: appRatingRulesList,
  };
}

