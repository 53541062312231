import * as actionTypes from './action-types';

export function fetchPackages() {
  return {
    type: actionTypes.FETCH_PACKAGES,
  };
}

export function setPackages(packages) {
  return {
    type: actionTypes.SET_PACKAGES,
    payload: { packages },
  };
}
export function generatePackage() {
  return {
    type: actionTypes.GENERATE_PACKAGE,
  };
}

export function startPackagePolling() {
  return {
    type: actionTypes.START_PACKAGE_POLLING,
  };
}

export function stopPackagePolling(status) {
  return {
    type: actionTypes.STOP_PACKAGE_POLLING,
    payload: { status },
  };
}

export function triggerDownloadPackage(packageId) {
  return {
    type: actionTypes.TRIGGER_DOWNLOAD_PACKAGE,
    payload: { packageId },
  };
}

export function endPackageDownload(response) {
  return {
    type: actionTypes.END_PACKAGE_DOWNLOAD,
    payload: { response },
  };
}

export function setPackageConfirmationDate(packageId){
  return {
    type: actionTypes.CONFIRM_PACKAGE, 
    payload: { packageId },
  };
}
