import * as ACTION_TYPES from '../actions/action-types';
import * as authSaga from './auth.saga';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { setCookie } from '@mdigital/common/dist/utils/cookies';
import HttpManager from '../../services/HttpManager';
import { API } from '../../common/api';
import { getNotificationByKampyleId } from '../../common/notification-mapper';
import { KAMPYLE_IDS, RESULT_CODES, REQUEST_HEADERS, PROVISIONS } from '../../common/enums';
import { getNgService, getSingleText } from '../../common/utils';
import { generateUuid } from '@mdigital/common/dist/utils/uuid';
import { setUniqueSessionRequestID } from '../actions/app.actions';
import { setProperty } from '../actions/properties.actions';
import { fetchProfile, getProfileState } from './profile.saga';
import { loginError } from '../actions/auth.actions';
import { checkProvision } from '../store/store-helper';
import { fetchResources } from '../actions/resources.actions';

const SCREEN_CAPTURE_STATE = 'screenCapture';

export function* handleLogin({ payload: { username, password } }) {
  try {
    yield HttpManager.post(API.AUTH.LOGIN, { username, password });
    yield setRequestHeader();
    yield loginFlow();
  } catch (e) {
    yield put(loginError(getNotificationByKampyleId(e.kampyleId)));
  }
}

export function* loginFlow(args) {
  try {
    // Handle properties
    const properties = yield HttpManager.get(API.PROPERTIES.FETCH);
    const property = authSaga.getProperty(properties); // importing authSaga for tests
    yield put(setProperty(property));
    // Profile in angular is being updated in 'fetchProfile'
    yield call(fetchProfile);

    const profileState = yield select(getProfileState);
    const isResourcesAllowed = profileState && UserRoles[profileState.userRole] > UserRoles.ROLE_VIEWER;
    
    if (isResourcesAllowed && checkProvision(PROVISIONS.REUSABLE_RESOURCES_LIBRARY)) {
      yield put(fetchResources());
    }

    // Initialize relevant data for angular
    getNgService((NebAppTree, GlobalSettings, NebProperty, Profile, NebGetProperties) => {
      NebAppTree.init();
      NebGetProperties.setProperties(properties);
      NebProperty.setPropertyId(property.id);
      GlobalSettings.init();
    });
    if(args && args.onLoginInitComplete){
      args.onLoginInitComplete();
    }
    navigateToLastPath(properties);

  } catch (e) {
    yield put(loginError(getSingleText('app.messages.unknownErrorOccurred')));
  }
}

function isScreenCaptureState(currentState){
  return currentState === SCREEN_CAPTURE_STATE;
}
function* setRequestHeader(){
  const sessionUUID = generateUuid();
  yield put(setUniqueSessionRequestID(sessionUUID));
  setCookie(REQUEST_HEADERS.X_MD_REQ_ID, sessionUUID);
}

export function getProperty(properties) {
  const { propertyId } = getLastVisitedDetails();
  const candidateProperty = properties.find((property) => property.id === propertyId);
  const fallbackProperty = properties[0];
  return candidateProperty || fallbackProperty;
}

export function getLastVisitedDetails() {
  let propertyId = null;
  let url = '/';
  getNgService((NebConf, $location) => {
    const lastPath = NebConf.getLastPath();
    const URL_MATCH_REGEX = /.*\/property\/(\d+).*/;
    const matchFromUrl = URL_MATCH_REGEX.exec($location.path());
    const matchFromLastPath = URL_MATCH_REGEX.exec(lastPath);
    if (matchFromUrl) {
      propertyId = Number(matchFromUrl[1]);
      url = $location.path();
    } else if (matchFromLastPath) {
      propertyId = Number(matchFromLastPath[1]);
      url = lastPath;
    }
  });

  return { propertyId, url };
}

function navigateToLastPath(properties) {
  getNgService((NebConf,$location,$state) => {
    const { propertyId, url } = getLastVisitedDetails();
    const isPropertyBelongToAccount = properties.find((property) => property.id === propertyId);
    const lastPath = NebConf.getLastPath();
    const isInScreenCapureState = isScreenCaptureState($state.current.name);
    if(isInScreenCapureState || (lastPath.indexOf(SCREEN_CAPTURE_STATE) > -1 && $state.current.name === 'login.signin')){
      !isInScreenCapureState && $location.path(lastPath);
    } else{
      $location.path(isPropertyBelongToAccount ? url : '/');
    }
  });
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield HttpManager.post(API.AUTH.FORGOT_PASSWORD, { username });
    if (response.resultCode === RESULT_CODES.FAILURE) {
      yield put(loginError(getNotificationByKampyleId(response.kampyleId)));
    }
  } catch (error) {
    if (error.kampyleId !== KAMPYLE_IDS.ILLEGAL_ARGUMENT_EXCEPTION) {
      yield put(loginError(getSingleText('app.messages.unknownErrorOccurred')));
    }
  }
}

export default function* () {
  yield takeLatest(ACTION_TYPES.LOGIN, handleLogin);
  yield takeLatest(ACTION_TYPES.LOGIN_FROM_SESSION, loginFlow);
  yield takeLatest(ACTION_TYPES.FORGOT_PASSWORD, forgotPassword);
}
