import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';
import { FORM_ACTION_TYPES, USER_ROLES } from '../../common/enums';
import { setNestedProperty } from '../../common/utils';

export default function(state = initialState.forms, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_FORM_VIEW_TYPE:
      const { viewType } = action.payload;
      return {
        ...state,
        viewType,
      };

    case ACTION_TYPES.SET_UNDELETED_FORMS:
      const { undeletedForms = [] } = action.payload;
      return { ...updatedState(state, undeletedForms), searchQuery: '' };

    case ACTION_TYPES.ADD_FORM:
      const { form } = action.payload;
      const nextUndeletedForms = [].concat(state.undeletedForms);
      nextUndeletedForms.push(form);
      return updatedState(state, nextUndeletedForms);

    case ACTION_TYPES.REMOVE_FORM:
      const _nextUndeletedForms = [].concat(state.undeletedForms)
        .filter((undeletedForm) => undeletedForm.id !== action.payload.formId);
      return updatedState(state, _nextUndeletedForms);

    case ACTION_TYPES.START_REFRESH_POLLING:
      return {
        ...state,
        isPollingFormsRefresh: true,
      };
    case ACTION_TYPES.STOP_REFRESH_POLLING:
      return {
        ...state,
        isPollingFormsRefresh: false,
      };

    case ACTION_TYPES.SET_TRIGGER_TYPE:
      const updateFormsTriggers = wrapperUpdateItemFunc(state, action, 'triggers.0.triggerType', 'triggerType');
      return updatedState(state, updateFormsTriggers);

    case ACTION_TYPES.SET_FORM_NAME:
      const updateFormsName = wrapperUpdateItemFunc(state, action, 'name', 'name');
      return updatedState(state, updateFormsName);

    case ACTION_TYPES.SET_PUBLISH_STATE:
      const updateFormsPublish = wrapperUpdateItemFunc(state, action, 'published', 'published');
      return updatedState(state, updateFormsPublish);

    case ACTION_TYPES.SET_FORM_DRAFT_ID:
      const updateFormsFormDraftId = wrapperUpdateItemFunc(state, action, 'formDraftId', 'formDraftId');
      return updatedState(state, updateFormsFormDraftId);

    case ACTION_TYPES.SET_LOCK_STATE:
      const updateFormsLock = wrapperUpdateItemFunc(state, action, 'isLocked', 'isLocked');
      return updatedState(state, updateFormsLock);

    case ACTION_TYPES.SET_DARK_MODE_STATE:
      const updateFormsDarkMode = wrapperUpdateItemFunc(state, action, 'isDarkModeEnabled', 'isDarkModeEnabled');
      return updatedState(state, updateFormsDarkMode);

    case ACTION_TYPES.SET_FORM_MODIFIED_DATE:
      const updateFormsTime = wrapperUpdateItemFunc(state, action, 'formChangeTime', 'formChangeTime');
      return updatedState(state, updateFormsTime);

    case ACTION_TYPES.SET_SORT_OPTIONS:
      const { sortBy, sortOrder } = action.payload;
      return { ...state, sortBy, sortOrder };

    case ACTION_TYPES.SET_FORM_SPINNER:
      const formsSpinnetActivation = wrapperUpdateItemFunc(state, action, 'isSpinnerActive', 'isSpinnerActive');
      return updatedState(state, formsSpinnetActivation);

    case ACTION_TYPES.FILTER_QUERY_STRING:
      return {
        ...state,
        actionType: FORM_ACTION_TYPES.FILTER,
        searchQuery: action.payload,
      };
    case ACTION_TYPES.CHECK_FORM:
      return {
        ...state,
        selectedForms: { ...state.selectedForms, [action.payload.formId]: true },
      };
    case ACTION_TYPES.UNCHECK_FORM:
      return {
        ...state,
        selectedForms: uncheckForm(state.selectedForms, action.payload.formId),
      };
    case ACTION_TYPES.CHECK_ALL_FORMS:
      return {
        ...state,
        selectedForms: checkAllForms(action.payload.formIds, state.selectedForms, action.payload.propertyRole),
      };
    case ACTION_TYPES.UNCHECK_ALL_FORMS:
      return {
        ...state,
        selectedForms: state.searchQuery ? uncheckAllFilteredForms(state.selectedForms, action.payload.formIds) : {},
      };

    case ACTION_TYPES.SET_FORMS_BEFORE_DELETE_FOLDER:
      const { payload: { formsBeforeDeletion } } = action;
      return {
        ...state,
        formsBeforeDeletion,
      };

    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState.forms,
        sortOrder: state.sortOrder,
        sortBy: state.sortBy,
      };
    case ACTION_TYPES.SET_PRELOADED_FORM_ID:
      const { preloadedFormId } = action.payload;
      return { ...state, preloadedFormId };
    case ACTION_TYPES.SET_CUSTOM_NOTICE_MODAL_STATE:
      return { ...state, customNoticeModalState:action.payload };
    case ACTION_TYPES.SET_FORM_TRIGGERING_DATA:
      const { triggeringData } = action.payload;
      const data = triggeringData[0];
      data.triggerScope = data.triggerScope || 'Foreground';
      setRuleIsActive(data.rules);
      return { ...state, triggeringData: data };
    case ACTION_TYPES.SET_FORM_IS_PUBLISHED:
      const { isPublished } = action.payload;
      return { ...state, isPublished };
    default:
      return state;
  }
}

function uncheckForm(checkedForms, idToUncheck) {
  const updatedCheckedForms = { ...checkedForms };
  delete updatedCheckedForms[idToUncheck];

  return updatedCheckedForms;
}

function checkAllForms(idsToCheck, checkedForms, propertyRole) {
  return idsToCheck.reduce((acc, form) => {
    if (!form.isLocked) {
      return checkedForms[form.formId] ? acc : { ...acc, [form.formId]: true };
    } else if (propertyRole >= USER_ROLES.ROLE_USER_GROUP_MANAGER) {
      return checkedForms[form.formId] ? acc : { ...acc, [form.formId]: true };
    } else {
      return acc;
    }
  }, checkedForms);
}

function uncheckAllFilteredForms(selectedForms, idsToUncheck) {
  if (idsToUncheck) {
    return idsToUncheck.reduce((acc, { formId }) => {
      if (acc[formId]) {
        // eslint-disable-next-line no-unused-vars
        const { [formId]: value, ...rest } = acc;
        acc = rest;
        return acc;
      }
      return acc;
    }, selectedForms);
  } else {
    return {};
  }

}


function wrapperUpdateItemFunc(state, action, property, actionKey) {
  return updateItemInCollection(state.undeletedForms || [], action.payload.formId, property, action.payload[actionKey]);
}

function updateItemInCollection(collection = [], id, property, value) {
  const resultCollection = collection.slice();
  const indexOfItem = resultCollection.findIndex((item) => item.id === id);
  setNestedProperty(resultCollection, `${indexOfItem}.${property}`, value);
  return resultCollection;
}

function updatedState(state, undeletedForms = []) {
  return {
    ...state,
    undeletedForms,
    count: undeletedForms.length,
  };
}

function setRuleIsActive(rules) {
  Object.keys(rules).map((rule) => { rules[rule].isActive = true ;});
}