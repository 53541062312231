import * as ACTION_TYPES from './action-types';

export function setFormViewType(viewType) {
  return {
    type: ACTION_TYPES.SET_FORM_VIEW_TYPE,
    payload: { viewType },
  };
}

export function setUndeletedForms(undeletedForms) {
  return {
    type: ACTION_TYPES.SET_UNDELETED_FORMS,
    payload: { undeletedForms },
  };
}

export function removeForm(formId) {
  return {
    type: ACTION_TYPES.REMOVE_FORM,
    payload: { formId },
  };
}

export function addForm(form) {
  return {
    type: ACTION_TYPES.ADD_FORM,
    payload: { form },
  };
}

export function setTriggerType(formId, triggerType) {
  return {
    type: ACTION_TYPES.SET_TRIGGER_TYPE,
    payload: { formId, triggerType },
  };
}

export function setPublishState(formId, published) {
  return {
    type: ACTION_TYPES.SET_PUBLISH_STATE,
    payload: { formId, published },
  };
}

export function setFormDraftId(formId, formDraftId) {
  return {
    type: ACTION_TYPES.SET_FORM_DRAFT_ID,
    payload: { formId, formDraftId },
  };
}

export function setLockState(formId, isLocked) {
  return {
    type: ACTION_TYPES.SET_LOCK_STATE,
    payload: { formId, isLocked },
  };
}

export function setName(formId, name) {
  return {
    type: ACTION_TYPES.SET_FORM_NAME,
    payload: { formId, name },
  };
}

export function setSortOptions(sortBy, sortOrder) {
  return {
    type: ACTION_TYPES.SET_SORT_OPTIONS,
    payload: { sortBy, sortOrder },
  };
}

export function setFormModifiedDate(formId, formChangeTime) {
  return {
    type: ACTION_TYPES.SET_FORM_MODIFIED_DATE,
    payload: { formId, formChangeTime },
  };
}

export function setFormSpinner(formId, isSpinnerActive) {
  return {
    type: ACTION_TYPES.SET_FORM_SPINNER,
    payload: { formId, isSpinnerActive },
  };
}

export function filterByQueryString(queryString) {
  return {
    type: ACTION_TYPES.FILTER_QUERY_STRING,
    payload: queryString,
  };
}

export function checkForm(formId) {
  return {
    type: ACTION_TYPES.CHECK_FORM,
    payload: { formId },
  };
}

export function uncheckForm(formId) {
  return {
    type: ACTION_TYPES.UNCHECK_FORM,
    payload: { formId },
  };
}

export function checkAllForms(formIds, propertyRole) {
  return {
    type: ACTION_TYPES.CHECK_ALL_FORMS,
    payload: { formIds, propertyRole },
  };
}

export function uncheckAllForms(formIds = {}) {
  return {
    type: ACTION_TYPES.UNCHECK_ALL_FORMS,
    payload: { formIds },
  };
}

export function getFormsBeforeFolderDeletion(folderId) {
  return {
    type: ACTION_TYPES.GET_FORMS_BEFORE_DELETE_FOLDER,
    payload: { folderId },
  };
}

export function setFormsBeforeDeletion(formsBeforeDeletion) {
  return {
    type: ACTION_TYPES.SET_FORMS_BEFORE_DELETE_FOLDER,
    payload: { formsBeforeDeletion },
  };
}

export function getUndeletedForms(resolvePromise) {
  return {
    type: ACTION_TYPES.GET_UNDELETED_FORMS,
    payload: { resolvePromise },
  };
}

export function setPreloadedFormId(preloadedFormId) {
  return {
    type: ACTION_TYPES.SET_PRELOADED_FORM_ID,
    payload: {
      preloadedFormId,
    },
  };
}

export function setCustomNoticeModalState(modalState){
  return{
    type:ACTION_TYPES.SET_CUSTOM_NOTICE_MODAL_STATE,
    payload:modalState,
  };
}

export function setDarkModeState(formId, isDarkModeEnabled) {
  return {
    type: ACTION_TYPES.SET_DARK_MODE_STATE,
    payload: { formId, isDarkModeEnabled },
  };
}

export function fetchFormTriggeringData(formID) {
  return {
    type: ACTION_TYPES.FETCH_FORM_TRIGGERING_DATA,
    payload: { formID },
  };
}

export function setFormTriggeringData(triggeringData) {
  return {
    type: ACTION_TYPES.SET_FORM_TRIGGERING_DATA,
    payload: { triggeringData },
  };
}

export function changeFormTargeting(newTriggerData) {
  return {
    type: ACTION_TYPES.CHANGE_FORM_TARGETING,
    payload: newTriggerData ,
  };
}

export function setFormIsPublished(isPublished) {
  return {
    type: ACTION_TYPES.SET_FORM_IS_PUBLISHED,
    payload: { isPublished },
  };
}