import * as ACTION_TYPES from './action-types';

export function login({ email ,password }) {
  return {
    type: ACTION_TYPES.LOGIN,
    payload: { username: email, password },
  };
}

export function logout() {
  return {
    type: ACTION_TYPES.LOGOUT,

  };
}

export function forgotPassword({ email }) {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD,
    payload: { username: email },
  };
}

export function loginError(errorMsg) {
  return {
    type: ACTION_TYPES.LOGIN_ERROR,
    payload: { loginError: errorMsg },
  };
}

export function cleanLoginError() {
  return {
    type: ACTION_TYPES.CLEAN_LOGIN_ERROR,
  };
}