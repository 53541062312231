import { initialState } from '../store/initial-state';
import { LOGOUT, SET_PROFILE, SET_FREEZE_MODE_STATE } from '../actions/action-types';

export default function(state = initialState.profile, action) {
  switch (action.type) {
    case SET_PROFILE:
      const { profile } = action.payload;
      return { ...state, ...profile, isLoggedIn: true };

    case LOGOUT:
      return initialState.profile;

    case SET_FREEZE_MODE_STATE:
      const freezeModeState = action.payload.state;
      return { ...state, freezeMode: freezeModeState };

    default:
      return state;
  }
}
