import * as ACTION_TYPES from '../actions/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import { API } from '../../common/api';
import HttpManager from '../../services/HttpManager';
import { setThemeData } from '../actions/theme.action';

function* getThemeByName({ payload }) {
  const  { formID, themeName }  = payload;
  const URL = `${API.APP_RATINGS.THEME}/${themeName}_${formID}`;
  try {
    const themeData = yield HttpManager.get(URL);
    yield put (setThemeData(themeData));
    return themeData;
  } catch(e) {
    console.error('an error accurse while trying to get theme by name');
  }
}

export default function* () {
  yield takeLatest(ACTION_TYPES.GET_THEME_BY_NAME, getThemeByName);
}