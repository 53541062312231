import { SET_AVAILABLE_RULES, SET_FORM_GENERIC_RULE, SET_OCQ_RULES } from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function ruleEngineReducer(
  state = initialState.ruleEngine,
  action
) {
  switch (action.type) {
    case SET_AVAILABLE_RULES:
      return { ...state, availableRules: action.payload };
    case SET_FORM_GENERIC_RULE:
      return { ...state,formGenericRule:action.genericRule };
    case SET_OCQ_RULES:
      const { rules } = action.payload;
      return { ...state, OCQRules: rules };
    default:
      return state;
  }
}
