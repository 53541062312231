import { SET_AVAILABLE_RULES, FETCH_AVAILABLE_FORM_RULES, FETCH_FORM_GENERIC_RULE, SET_FORM_GENERIC_RULE, FETCH_OCQ_RULES, SET_OCQ_RULES } from './action-types';

export function setAvailableRules(rules) {
  return {
    type: SET_AVAILABLE_RULES,
    payload: rules,
  };
}

export function fetchAvailableFormRules(formId) {
  return {
    type: FETCH_AVAILABLE_FORM_RULES,
    formId,
  };
}

export function fetchGenericRuleByFormId(formId,resolvePromise,rejectPromise){
  return{
    type:FETCH_FORM_GENERIC_RULE,
    payload:{ formId,resolvePromise,rejectPromise },
  };
}

export function setFormGenericRule(genericRule){
  return{
    type:SET_FORM_GENERIC_RULE,
    genericRule,
  };
}

export function fetchOCQRules(){
  return {
    type: FETCH_OCQ_RULES,
  };
}

export function setOCQRules(rules) {
  return {
    type: SET_OCQ_RULES,
    payload: rules ,
  };
}