import { SET_APP_RATING_DEFUALT_BANNER_THEME, SET_THEME_DATA } from '../actions/action-types';
import { initialState } from '../store/initial-state';
  
export default function(state = initialState.theme, action) {
  switch (action.type) {
    case SET_APP_RATING_DEFUALT_BANNER_THEME:
      const { bannerThemeDefaultData } = action.payload;
      return { ...state, appRating: { ...state.appRating, bannerThemeDefaultData } };
    case SET_THEME_DATA:
      return { ...state, forms: { ...state.forms , theme: action.payload } };
    default:
      return state;
  }
}