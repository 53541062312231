import {
  SET_APP_RATING_RULES
} from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.appRatingRules, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_APP_RATING_RULES:
      return payload;
    default:
      return state;
  }
}