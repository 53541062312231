import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.auth, action) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_ERROR:
      const { loginError } = action.payload;
      return { ...state, loginError };
    case ACTION_TYPES.CLEAN_LOGIN_ERROR:
      return initialState.auth;
    default:
      return state;
  }
}