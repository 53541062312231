import { takeLatest, call, fork, put, take, cancel } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';

import * as actionTypes from '../actions/action-types';
import * as packagesActions from '../actions/packages.actions';
import * as resourcesActions from '../actions/resources.actions';
import * as profileActions from '../actions/profile.actions';
import { pushNotification } from '../../common/utils';

import HttpManager from '../../services/HttpManager';
import { API, PLACEHOLDER } from '../../common/api';
import { NOTIFICATION_TYPES, PACKAGE_STATUS } from '../../common/enums';
import { getNotificationByKampyleId } from '../../common/notification-mapper';
import { POLLING_INTERVAL, UNKNOWN_ERROR } from '../../common/constants';
import { isFreezeMode } from '../store/store-helper';
import { KAMPYLE_IDS } from '../../common/enums';


const NOTIFICATIONS = {
  PACKAGE_CREATED_START: 'Creating your package, this will take a couple of minutes.',
  PACKAGE_CREATED_SUCCESSFULLY: 'Package created successfully',
  PACKAGE_CONFIRMED_SUCCESSFULLY: 'Good job, you are out of "Freeze mode"! The updates were successfully installed',
  COULD_NOT_CONFIRM_PACKAGE: 'Package confirmation failed, please try again or reach out to your digital expert',
  PACKAGE_CONFIRMED_SUCCESSFULLY_NON_FREEZE_MODE: 'Good job! The updates were successfully installed',
};
export function* fetchPackages() {
  try {
    const pkgs = yield call(HttpManager.get, API.PACKAGES.FETCH);
    yield put(packagesActions.setPackages(pkgs));
  } catch (e) {
    pushNotification({ type: NOTIFICATION_TYPES.FAILURE, UNKNOWN_ERROR });
  }
}

export function* generatePackageFlow() {
  try {
    yield put(packagesActions.startPackagePolling());
    const pkg = yield call(HttpManager.post, API.PACKAGES.GENERATE);
    if(pkg.status === PACKAGE_STATUS.GENERATING){
      pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: NOTIFICATIONS.PACKAGE_CREATED_START });
    }
    const pollingTask = yield fork(pollPackageStatus, pkg.id);
    const { payload } = yield take(actionTypes.STOP_PACKAGE_POLLING);
    yield cancel(pollingTask);

    if (payload.status === PACKAGE_STATUS.READY) {
      pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: NOTIFICATIONS.PACKAGE_CREATED_SUCCESSFULLY });
      yield fetchPackages();
    } else {
      const message = getNotificationByKampyleId(KAMPYLE_IDS.PACKAGE_GENERATION_FAILED);
      pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message });
    }
  } catch (e) {
    const kampyleException = e.kampyleId;
    const message = getNotificationByKampyleId(kampyleException);
    yield put(packagesActions.stopPackagePolling());
    pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message });
  }
}

export function* pollPackageStatus(packageId) {
  try {
    const api = API.PACKAGES.STATUS.replace(PLACEHOLDER.PACKAGE_ID, packageId);
    while (true) {
      const pkgStatus = yield call(HttpManager.get, api);
      if (pkgStatus !== PACKAGE_STATUS.GENERATING) {
        yield put(packagesActions.stopPackagePolling(pkgStatus));
      } else {
        yield delay(POLLING_INTERVAL);
      }
    }
  } catch (e) {
    yield put(packagesActions.stopPackagePolling(PACKAGE_STATUS.FAIL));
  }
}

export function* downloadPackageEnded({ payload: { response } }) {
  if (!response.ok) {
    const result = yield response.json();
    const kampyleException = result.kampyleId;
    const message = getNotificationByKampyleId(kampyleException);
    pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message });
  }
}

export function* setPackageConfirmDate({ payload: { packageId } }){
  try{
    const api = API.PACKAGES.SET_CONFIRMATION.replace(PLACEHOLDER.PACKAGE_ID, packageId);
    yield call(HttpManager.post, api);
    const freezeMode = isFreezeMode();
    if (freezeMode) {
      pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: NOTIFICATIONS.PACKAGE_CONFIRMED_SUCCESSFULLY });
      yield put(profileActions.setFreezeModeState(false));
    } else {
      pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: NOTIFICATIONS.PACKAGE_CONFIRMED_SUCCESSFULLY_NON_FREEZE_MODE });
    }
    yield fetchPackages();
    yield put(resourcesActions.fetchResources());
  } catch(e){
    const kampyleException = e.kampyleId;
    const message = getNotificationByKampyleId(kampyleException, NOTIFICATIONS.COULD_NOT_CONFIRM_PACKAGE);
    pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message });
  }
}

export default function* () {
  yield takeLatest(actionTypes.FETCH_PACKAGES, fetchPackages);
  yield takeLatest(actionTypes.GENERATE_PACKAGE, generatePackageFlow);
  yield takeLatest(actionTypes.END_PACKAGE_DOWNLOAD, downloadPackageEnded);
  yield takeLatest(actionTypes.CONFIRM_PACKAGE, setPackageConfirmDate);
}