import { FETCH_RESOURCES, SET_RESOURCES, DELETE_RESOURCE } from './action-types';

export function fetchResources() {
  return {
    type: FETCH_RESOURCES,
  };
}

export function setResources(resources) {
  return {
    type: SET_RESOURCES,
    payload: { resources },
  };
}

export function deleteResource(id) {
  return {
    type: DELETE_RESOURCE,
    payload: { id },
  };
}