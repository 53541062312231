import { FETCH_MOBILE_PREVIEW_DETAILS, SET_MOBILE_PREVIEW_MODAL_SHOWN, SET_MOBILE_PREVIEW_URL } from './action-types';

export function setMobilePreviewModalShown(isModalShown) {
  return {
    type: SET_MOBILE_PREVIEW_MODAL_SHOWN,
    payload: { isModalShown },
  };
}

export function setMobilePreviewURL(integrationURL) {
  return {
    type: SET_MOBILE_PREVIEW_URL,
    payload: { integrationURL },
  };
}

export function fetchMobilePreviewDetails(formId) {
  return {
    type: FETCH_MOBILE_PREVIEW_DETAILS,
    payload: { formId },
  };
}
