import { SET_APP_RATING_DEFUALT_BANNER_THEME, 
  GET_THEME_BY_NAME, 
  SET_THEME_DATA } from './action-types';

export function setAppRatingDefualtBannerTheme(themeData = {}) {
  return {
    type: SET_APP_RATING_DEFUALT_BANNER_THEME,
    payload: themeData,
  };
}

export function getThemeByName(formID , themeName){
  return {
    type: GET_THEME_BY_NAME,
    payload: { formID, themeName },
  };
}

export function setThemeData(themeData) {
  return {
    type: SET_THEME_DATA,
    payload: themeData,
  };
}