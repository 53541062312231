import { KAMPYLE_IDS } from '../../common/enums';
import { setNestedProperty } from '../../common/utils';
import { freezeModeErrorHandler,deleteResoureErrorHandler } from './error-handlers';

setNestedProperty(window,'getCallbackByKampyleId',getCallbackByKampyleId); //for angular

const callbackErrorMapper = {
  [KAMPYLE_IDS.FREEZE_MODE]: freezeModeErrorHandler,
  [KAMPYLE_IDS.RESOURCE_DELETED]: deleteResoureErrorHandler,
};

export function getCallbackByKampyleId(kampyleId, fallback = () => console.log(`Error occurred with kampyleId ${kampyleId}`)) {
  return callbackErrorMapper[kampyleId] || fallback();
}