import {
  SORT_ORDER,
  FORM_VIEW_TYPES,
  REQUEST_HEADERS
} from '../../common/enums';
import { getCookie } from '@mdigital/common/dist/utils/cookies';

export const initialState = {
  app: {
    uniqueSessionRequestID: getCookie(REQUEST_HEADERS.X_MD_REQ_ID),
    isShowSystemModal: false,
    isShowSurveyUpgradeModal: false,
  },
  mobilePreview: {
    integrationURL: null,
    isModalShown: false,
  },
  folders: {
    selectedFolder: null,
    allFolders: [],
    isPolling: false,
    foldersInProcess: {},
  },
  forms: {
    isPollingFormsRefresh: false,
    undeletedForms: null,
    viewType: FORM_VIEW_TYPES.GRID,
    sortBy: null,
    sortOrder: SORT_ORDER.DESC,
    actionType: '',
    searchQuery: null,
    selectedForms: {},
    formsBeforeDeletion: null,
    preloadedFormId: null,
    customNoticeModalState:{
      isShown: false,
    },
  },
  localization: {
    formId: null,
    allLanguages: null,
    publishedId: null,
    draftId: null,
    version: null,

    // data to send to the server
    fileName: '',
    availableLanguages: [],
    formLocalizationSettings: {
      defaultLanguage: null,
      useCustomParam: false,
      customParam: null,
    },
  },
  appRatings: [],
  appRatingRules: [],
  customParameters: [],
  allEngagements: [],
  allFormsEngagements: [],
  allAppRatingsEngagements: [],
  customParametersActions: {},
  reusableFields: {},
  profile: {
    isLoggedIn: false,
    freezeMode: false,
  },
  property: {
    id: null,
  },
  auth: {
    loginError: null,
  },
  packages: {
    allPackages: null,
    isPolling: false,
    packageToDownload: null,
  },
  resources: {
    allResources: null,
  },
  ruleEngine: {
    availableRules: [],
    formGenericRule:{},
  },
  theme: {
    appRating:{
      bannerThemeDefaultData: [],
    },
    forms:{},
  },
};
