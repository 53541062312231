import { store } from './configure-store';

export function dispatch(action) {
  store.dispatch(action);
}

export function getPropertyId() {
  const { property: { id } } = store.getState();
  return id;
}

export function shouldPoll() {
  const { folders: { isPolling } } = store.getState();
  return isPolling;
}

export function shouldPollRefresh() {
  const { forms: { isPollingFormsRefresh } } = store.getState();
  return isPollingFormsRefresh;
}

export function getPollingInterval() {
  const { profile: { formFoldersPollingInterval } } = store.getState();
  return formFoldersPollingInterval;
}

export function getFolders() {
  const { folders: { allFolders } } = store.getState();
  return allFolders;
}

export function getSelectedFolder() {
  const { folders: { selectedFolder } } = store.getState();
  return selectedFolder;
}

export function getFolderById(id, folders = getFolders()) {
  return folders.find((folder) => folder.id === id);
}

export function getRootFolder(folders = getFolders()) {
  return folders.find((folder) => folder.root);
}

export function checkProvision(provision) {
  const { profile: { provisioning = {} } } = store.getState();
  return (provisioning[provision] === true || provisioning[provision] === 'true');
}

export function checkSetting(setting) {
  const { profile } = store.getState();
  return (profile[setting] === true || profile[setting] === 'true');
}

export function getFoldersInProcess() {
  const { folders: { foldersInProcess } } = store.getState();
  return foldersInProcess;
}

export function getUniqueSessionRequestID(){
  const { app: { uniqueSessionRequestID } } = store.getState();
  return uniqueSessionRequestID;
}

export function getPropertyType() {
  const { profile: { propertyType } } = store.getState();
  return propertyType;
}

export function getProfile() {
  const { profile } = store.getState();
  return profile;
}

export function getResourceById(id) {
  const { resources: { allResources } } = store.getState();
  return (allResources || []).find((resource) => resource.id === id);
}

export function getAllResources() {
  const { resources: { allResources } } = store.getState();
  return allResources;
}

export function isFreezeMode() {
  const { profile: { freezeMode } } = store.getState();
  return freezeMode;
}

export function getAppRatingBannerThemeDefaultData() {
  const { theme: { appRating : { bannerThemeDefaultData } } } = store.getState();
  return bannerThemeDefaultData;
}
