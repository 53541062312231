import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { setNestedProperty } from '../../common/utils';

import reducer from '../reducers';
import sagas from '../sagas';
import { initialState } from './initial-state';

const sagaMiddleware = createSagaMiddleware();


const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;


const store = createStore(
  reducer,
  initialState || {},
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
);


// then run the saga
sagaMiddleware.run(sagas) ;

setNestedProperty(window, 'DIG_REDUX.store', store);

export { store };