import {
  FETCH_APP_RATINGS,
  TOGGLE_APP_RATING_PUBLISHED_STATE,
  REMOVE_APP_RATING_FROM_STATE,
  TOGGLE_APP_RATING_LOCKED_STATE,
  DELETE_APP_RATING,
  SET_APP_RATING_LIST,
  SET_APP_RATING_PUBLISHED_STATE,
  SET_APP_RATING_LOCKED_STATE,
  CREATE_APP_RATING,
  APPEND_APP_RATING,
  UPDATE_APP_RATING,
  GET_APP_RATING,
  ENRICH_APP_RATING,
  SET_APP_RATING,
  SET_APP_RATING_DARK_MODE_STATE,
  TOGGLE_APP_RATING_DARK_MODE_STATE,
  FETCH_APP_RATING_BANNER_THEME
} from './action-types';

export function fetchAppRatings() {
  return {
    type: FETCH_APP_RATINGS,
  };
}

export function createAppRating(data) {
  return {
    type: CREATE_APP_RATING,
    payload: data,
  };
}

export function getAppRating(id, done) {
  return {
    type: GET_APP_RATING,
    payload: { id, done },
  };
}

export function enrichAppRating(id, data) {
  return {
    type: ENRICH_APP_RATING,
    payload: { id, data },
  };
}

export function setAppRating(id, data) {
  return {
    type: SET_APP_RATING,
    payload: { id, data },
  };
}

export function updateAppRating(data) {
  const { id } = data;
  return {
    type: UPDATE_APP_RATING,
    payload: { id, data },
  };
}

export function appendAppRating(newAppRating) {
  return {
    type: APPEND_APP_RATING,
    payload: newAppRating,
  };
}

export function deleteAppRating(id) {
  return {
    type: DELETE_APP_RATING,
    payload: { id },
  };
}

export function toggleAppRatingPublishedState({ id, newState }) {
  return {
    type: TOGGLE_APP_RATING_PUBLISHED_STATE,
    payload: { id, newState },
  };
}

export function toggleAppRatingLockedState({ id, newState }) {
  return {
    type: TOGGLE_APP_RATING_LOCKED_STATE,
    payload: { id, newState },
  };
}

export function setAppRatingsList(appRatingsList) {
  return {
    type: SET_APP_RATING_LIST,
    payload: appRatingsList,
  };
}

export function removeAppRating(id) {
  return {
    type: REMOVE_APP_RATING_FROM_STATE,
    payload: { id },
  };
}

export function setAppRatingPublishedState({ id, newState }) {
  return {
    type: SET_APP_RATING_PUBLISHED_STATE,
    payload: { id, newState },
  };
}

export function setAppRatingLockedState({ id, newState }) {
  return {
    type: SET_APP_RATING_LOCKED_STATE,
    payload: { id, newState },
  };
}

export function setAppRatingDarkModeState({ id, newState }) {
  return {
    type: SET_APP_RATING_DARK_MODE_STATE,
    payload: { id, newState },
  };
}

export function toggleAppRatingDarkModeState({ id, newState }) {
  return {
    type: TOGGLE_APP_RATING_DARK_MODE_STATE,
    payload: { id, newState },
  };
}

export function fetchAppRatingDefualtBannerTheme() {
  return {
    type: FETCH_APP_RATING_BANNER_THEME,
  };
}