import { initialState } from '../store/initial-state';
import * as actionTypes from '../actions/action-types';

export default function(state = initialState.packages, action = {}) {
  switch (action.type) {

    case actionTypes.SET_PACKAGES: {
      const { packages } = action.payload;
      return { ...state, allPackages: packages };
    }

    case actionTypes.START_PACKAGE_POLLING:
      return { ...state, isPolling: true };

    case actionTypes.STOP_PACKAGE_POLLING:
      return { ...state, isPolling: false };

    case actionTypes.TRIGGER_DOWNLOAD_PACKAGE:
      const { packageId } = action.payload;
      return { ...state, packageToDownload: packageId };

    case actionTypes.END_PACKAGE_DOWNLOAD:
      return { ...state, packageToDownload: null };

    default:
      return state;
  }
}