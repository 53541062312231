import { initialState } from '../store/initial-state';
import { SET_RESOURCES } from '../actions/action-types';

export default function(state = initialState.resources, { type, payload }) {
  switch (type) {
    case SET_RESOURCES: {
      return { ...state, allResources: payload.resources };
    }

    default: {
      return state;
    }
  }
}