import * as ACTION_TYPES from './action-types';

export function getFolders() {
  return {
    type: ACTION_TYPES.FETCH_FOLDERS,
  };
}

export function setFolders(allFolders) {
  return {
    type: ACTION_TYPES.SET_FOLDERS,
    payload: { allFolders },
  };
}

export function setSelectedFolder(selectedFolder) {
  return {
    type: ACTION_TYPES.SET_SELECTED_FOLDER,
    payload: { selectedFolder },
  };
}

export function createFolder(name) {
  return {
    type: ACTION_TYPES.CREATE_NEW_FOLDER,
    payload: { name },
  };
}

export function renameFolder(id, name) {
  return {
    type: ACTION_TYPES.RENAME_FOLDER,
    payload: { id, name },
  };
}

export function moveFormsToFolder(folderId, formIds, resolvePromise) {
  return {
    type: ACTION_TYPES.MOVE_FORMS_TO_FOLDER,
    payload: { folderId, formIds, resolvePromise },
  };
}

export function deleteFolder(folderId) {
  return {
    type: ACTION_TYPES.DELETE_FOLDER,
    payload: { folderId },
  };
}

export function startPolling() {
  return {
    type: ACTION_TYPES.START_POLLING,
  };
}

export function stopPolling() {
  return {
    type: ACTION_TYPES.STOP_POLLING,
  };
}

export function setFolderInProcess(jobType, folder) {
  return {
    type: ACTION_TYPES.SET_FOLDER_IN_PROCESS,
    payload: { jobType, folder },
  };
}

export function endFoldersProcess(jobType, completedFolders) {
  return {
    type: ACTION_TYPES.END_FOLDERS_PROCESS,
    payload: { jobType, completedFolders },
  };
}

export function addFolder(newFolder) {
  return {
    type: ACTION_TYPES.ADD_NEW_FOLDER,
    payload: { newFolder },
  };
}