import { FETCH_MOBILE_PREVIEW_DETAILS } from '../actions/action-types';
import { takeEvery, put } from 'redux-saga/effects';
import { API } from '../../common/api';
import { setMobilePreviewURL } from '../actions/mobilePreview.actions';
import HttpManager from '../../services/HttpManager';

function* fetchMobilePreviewDetails({ payload: { formId } }) {
  try {
    const previewDetails = yield HttpManager.get(API.MOBILE_PREVIEW_DETAILS.FETCH);
    const { url, previewAppShortTermToken } = previewDetails;
    yield buildMobiePreviewURL(url, previewAppShortTermToken, formId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('failed to fetch mobile preview details', e);
  }
}

function* buildMobiePreviewURL(baseUrl, token, formId) {
  let mobilePreviewURL = `${baseUrl}?token=${token}`;
  if (formId) {
    mobilePreviewURL = `${mobilePreviewURL}&formId=${formId}`;
  }
  yield put(setMobilePreviewURL(mobilePreviewURL));
}

export default function* () {
  yield takeEvery(FETCH_MOBILE_PREVIEW_DETAILS, fetchMobilePreviewDetails);
}