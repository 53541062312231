import { bind } from '@mdigital/components/dist/decorators';
import { getCookie } from '@mdigital/common/dist/utils/cookies';
import { PLACEHOLDER } from '../common/api';
import { HTTP_METHODS, REQUEST_HEADERS, RESULT_CODES } from '../common/enums';
import { getCallbackByKampyleId } from '../react/common/callback-error-mapper';
import { logout, setUniqueSessionRequestID } from '../redux/actions/app.actions';
import { dispatch, getPropertyId, getUniqueSessionRequestID } from '../redux/store/store-helper';

class HttpManager {

  @bind
  get(url) {
    return this.setupAndExtractData(url, this.getConfiguration({ method: HTTP_METHODS.GET }));
  }

  @bind
  post(url, data) {
    return this.setupAndExtractData(url, this.getConfiguration({ method: HTTP_METHODS.POST, data }));
  }

  @bind
  delete(url, data) {
    return this.setupAndExtractData(url, this.getConfiguration({ method: HTTP_METHODS.DELETE, data }));
  }

  @bind
  put(url, data) {
    return this.setupAndExtractData(url, this.getConfiguration({ method: HTTP_METHODS.PUT, data }));
  }

  @bind
  getResponse(url) {
    return this.setup(url, this.getConfiguration({ method: HTTP_METHODS.GET }));
  }

  getConfiguration({ method, data }) {
    const isFileType = data instanceof FormData;
    const config = {
      method,
      headers: {
        [REQUEST_HEADERS.X_XSRF_TOKEN]: getCookie('XSRF-TOKEN'),
        [REQUEST_HEADERS.X_MD_REQ_ID]: getUniqueSessionRequestID(),
        [REQUEST_HEADERS.TRACE_PARENT_STRING]: generateTraceParent(),
      },
      credentials: 'include',
      body: isFileType ? data : JSON.stringify(data),
    };
    if (!isFileType) {
      config.headers[REQUEST_HEADERS.CONTENT_TYPE] = 'application/json';
    }
    return config;
  }

  setup(url, config) {
    const formattedUrl = url.replace(PLACEHOLDER.PROPERTY_ID, getPropertyId());
    const request = new Request(formattedUrl, config);

    // using bind not for the context/this but to bind it the request parameter, and by that preventing from creating anonymous function
    return new Promise(this.process.bind(this, request));
  }

  setupAndExtractData(url, config) {
    return this.setup(url, config).then((res) => res.data);
  }

  async process(request, resolve, reject) {
    const response = await this.send(request);
    let data = {};

    try {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        data = await response.json();
      } else {
        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const isAttachementResponse = contentDispositionHeader && contentDispositionHeader.includes('name="attachment"');
        if (isAttachementResponse) {
          data = await response.blob();
        } else {
          data = await response.text();
        }
      }
    } catch (e) {
      this.onError(e, reject);
    }
    response.ok ? resolve({ headers: response.headers, data: data }) : this.onError(data, reject);
  }


  onError(err, reject) {
    const callbackError = getCallbackByKampyleId(err.kampyleId);

    if (err.resultCode === RESULT_CODES.UNAUTHORIZED) {
      logOutAndRedirect();
    } else if(err.resultCode === RESULT_CODES.FORBIDDEN){
      redirectToAccessDeniedPage();
    } else if(err.kampyleId && callbackError ){
      callbackError();
    }

    reject(err);
  }

  send(request) {
    return fetch(request);
  }
}

function generateTraceParent() {
	return "00-".concat(randomHex(32))
	.concat("-")
	.concat(randomHex(16))
	.concat("-01");
}

function randomHex(length) {
  const hexChars = "0123456789abcdef"
	let str = ""
	for (let i = 0; i < length; i++) {
		str += hexChars[Math.floor(Math.random() * 16)]
	}
	return str
}

function redirectToLoginPage() {
  // from angular docs: (source https://docs.angularjs.org/api/ng/function/angular.injector)
  // Sometimes you want to get access to the injector of a currently running AngularJS app from outside AngularJS.
  // Perhaps, you want to inject and compile some markup after the application has been bootstrapped.
  // You can do this using the extra injector() added to JQuery/jqLite elements
  angular.element(document.body).injector().invoke(($location) => $location.path('/login/signin'));
}

function redirectToAccessDeniedPage() {
  const propertyId = getPropertyId();
  angular.element(document.body).injector().invoke(($location) => $location.path(`/app/property/${propertyId}/pages/accessDenied`));
}

export function logOutAndRedirect(){
  dispatch(logout());
  dispatch(setUniqueSessionRequestID());
  redirectToLoginPage();
}

export default new HttpManager();
