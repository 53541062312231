import * as ACTION_TYPES from '../actions/action-types';
import { put, takeLatest } from 'redux-saga/effects';
import { getSelectedFolder, getFolderById, checkProvision } from '../store/store-helper';
import {
  uncheckAllForms,
  setUndeletedForms,
  setFormsBeforeDeletion,
  setPreloadedFormId,
  setFormTriggeringData,
  setTriggerType
} from '../actions/form.actions';
import { getFolders } from '../actions/folder.actions';
import HttpManager from '../../services/HttpManager';
import { API, PLACEHOLDER } from '../../common/api';
import { FOLDER_ACTIONS, NOTIFICATION_TYPES, PROVISIONS } from '../../common/enums';
import { handleFolderError } from './folders.saga';
import { isFolderInDeleteStatus, pushNotification } from '../../common/utils';
import { getNestedProperty } from '@mdigital/common/dist/utils/object';

function isPreloadedForm(form = {}) {
  const { triggerType, isPreloaded } = getNestedProperty(form, 'triggers.0', {});
  return triggerType === FormTriggerTypes.CODE && isPreloaded;
}

function findPreloadedForm(forms = []) {
  return (forms.find(isPreloadedForm) || {}).id;
}

function* getUndeletedForms(payload) {
  const { resolvePromise } = payload;
  let undeletedForms = [];
  try {
    undeletedForms = yield HttpManager.get(API.FORMS.GET_UNDELETED_FORMS);
    const preloadedFormId = findPreloadedForm(undeletedForms);
    if (preloadedFormId) {
      yield put(setPreloadedFormId(preloadedFormId));
    }
    yield put(setUndeletedForms(undeletedForms));
  } catch (e) {
    console.log({ e });
    // TODO: handle
  } finally {
    yield resolvePromise && resolvePromise();
  }
}

export function* getFormsBySelectedFolder() {
  const selectedFolder = getSelectedFolder();
  if (selectedFolder && !isFolderInDeleteStatus(selectedFolder)) {
    
    let undeletedForms;
    undeletedForms = yield getFormsByFolderId({ folderId: selectedFolder.id });
    yield put(uncheckAllForms());
    yield put(setUndeletedForms(undeletedForms));
  }
}

export function* getFormsByFolderId({ folderId }) {
  let undeletedForms = [];

  try {
    undeletedForms = yield HttpManager.get(API.FORMS.GET_FORMS_BY_FOLDER_ID.replace(PLACEHOLDER.ID, folderId));
  } catch (e) {
    // TODO: handle
  }
  return undeletedForms;
}

function* getFormsBeforeDeleteFolder({ payload }) {
  const formsBeforeDeletion = yield getFormsByFolderId({ folderId: payload.folderId });
  yield put(setFormsBeforeDeletion(formsBeforeDeletion));
}

function* moveFormsToFolder({ payload: { folderId, formIds, resolvePromise } }) {
  const type = formIds.length > 1 ? 'forms' : 'form';
  try {
    yield HttpManager.post(API.FORMS.MOVE, {
      formIds,
      destinationFolderId: folderId,
    });
    yield put(getFolders());
    yield getFormsBySelectedFolder();

    const { name } = getFolderById(Number(folderId));
    pushNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${formIds.length} ${type} successfully moved to ${name}`,
    });
    yield resolvePromise && resolvePromise();
  } catch (e) {
    const errorMessage = `An error occurred while moving the ${type}, please try again.`;
    yield handleFolderError(e.kampyleId, FOLDER_ACTIONS.MOVE, errorMessage);
  }
}

function* fetchFormTriggeringData({ payload }) {
  const { formID } = payload;
  const URL = `${API.FORMS.GET_FORM_TRIGGERING}/${formID}/triggers`;
  try {
    const triggeringData = yield HttpManager.get(URL);
    yield put(setFormTriggeringData(triggeringData));
    return triggeringData;
  } catch(e) {
    console.error('An error occurred while trying to fetch form triggering data');
  }
}

function* changeFormTargeting({ payload }) {
  const  { formID }  = payload;
  const URL = `${API.FORMS.GET_FORM_TRIGGERING}/${formID}/triggers`;
  try {
    yield HttpManager.post(URL , payload);
    const triggeringData = yield HttpManager.get(URL);
    yield put(setFormTriggeringData(triggeringData));
    yield put(setTriggerType(formID, payload.triggerType));
  } catch(e) {
    console.error('An error occurred while trying to change form targeting');
  }
}

function constructFormTargetingURL({ formId, id, isDraftEnabled, isDarkMode, triggerType }) {
  const targetingContract = '.MobileInvitationContract';
  let basePath = isDraftEnabled ? `${API.FORMS.GET_FORM_TRIGGERING}/drafts` : API.FORMS.GET_FORM_TRIGGERING;
  let versionPath = isDarkMode && triggerType === targetingContract ? '/v2' : '';
  return `${basePath}/${formId}/triggers${versionPath}/${id}`;
}

function* saveFormTargeting({ payload: { triggerState } }) {
  const targetingContract = '.MobileInvitationContract';
  const { formId, id, triggerType } = triggerState.trigger;
  const isDraftEnabled = checkProvision(PROVISIONS.ENABLE_SURVEY_DRAFTS);
  const isDarkMode = checkProvision(PROVISIONS.MOBILE_SDKV2_NATIVE_DARK_MODE);

  const URL = constructFormTargetingURL({ formId, id, isDraftEnabled, isDarkMode, triggerType });

  let data = isDarkMode && targetingContract === triggerType ? {
    mobileThemes: [triggerState.theme],
    triggerEntityContract: triggerState.trigger,
  } : triggerState.trigger;

  try {
    yield HttpManager.post(URL , data);
  } catch(e) {
    console.error('An error occurred while trying to save form targeting');
  }
}

export default function* () {
  yield takeLatest(ACTION_TYPES.SET_SELECTED_FOLDER, getFormsBySelectedFolder);
  yield takeLatest(ACTION_TYPES.GET_FORMS_BEFORE_DELETE_FOLDER, getFormsBeforeDeleteFolder);
  yield takeLatest(ACTION_TYPES.MOVE_FORMS_TO_FOLDER, moveFormsToFolder);
  yield takeLatest(ACTION_TYPES.GET_UNDELETED_FORMS, getUndeletedForms);
  yield takeLatest(ACTION_TYPES.FETCH_FORM_TRIGGERING_DATA, fetchFormTriggeringData);
  yield takeLatest(ACTION_TYPES.CHANGE_FORM_TARGETING, changeFormTargeting);
  yield takeLatest(ACTION_TYPES.SAVE_FORM_TARGETING, saveFormTargeting);
}