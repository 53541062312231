import {
  SET_MOBILE_PREVIEW_URL,
  SET_MOBILE_PREVIEW_MODAL_SHOWN
} from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.mobilePreview, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_MOBILE_PREVIEW_URL:
      return { ...state, ...payload };
    case SET_MOBILE_PREVIEW_MODAL_SHOWN:
      return { ...state, ...payload };
    default:
      return state;
  }
}