import { all } from 'redux-saga/effects';
import appSaga from './app.saga';
import foldersSaga from './folders.saga';
import formsSaga from './forms.saga';
import refreshSaga from './refresh.saga';
import localizationSaga from './localization.saga';
import authSaga from './auth.saga';
import appRatingsSaga from './appRatings.saga';
import appRatingRulesSaga from './appRatingRules.saga';
import packagesSaga from './packages.saga';
import profileSaga from './profile.saga';
import resourcesSaga from './resources.saga';
import ruleEngineSaga from './rule-engine.saga';
import mobilePreviewSaga from './mobilePreview.saga';
import themeSaga from './theme.saga';

export default function* () {
  yield all([
    appRatingRulesSaga(),
    appRatingsSaga(),
    appSaga(),
    authSaga(),
    foldersSaga(),
    formsSaga(),
    refreshSaga(),
    localizationSaga(),
    mobilePreviewSaga(),
    packagesSaga(),
    profileSaga(),
    resourcesSaga(),
    ruleEngineSaga(),
    themeSaga()
  ]);
}
