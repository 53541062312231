import * as ACTION_TYPES from './action-types';

export function setLocalizationEngagementId(engagementId) {
  return setLocalizationFormId(engagementId);
}

export function setLocalizationFormId(formId) {
  return {
    type: ACTION_TYPES.SET_LOCALIZATION_FORM_ID,
    payload: { formId },
  };
}

export function setFormLocalizationSettings(formLocalizationSettings) {
  return {
    type: ACTION_TYPES.SET_FORM_LOCALIZATION_SETTINGS,
    payload: { formLocalizationSettings },
  };
}

export function setImportFileName(fileName) {
  return {
    type: ACTION_TYPES.SET_IMPORT_FILE_NAME,
    payload: { fileName },
  };
}

export function setAvailableLanguages(languages) {
  return {
    type: ACTION_TYPES.SET_AVAILABLE_LAMNGUAGES,
    payload: { languages },
  };
}

export function setUseCustomParam(useCustomParam) {
  return {
    type: ACTION_TYPES.SET_USE_CUSTOM_PARAM,
    payload: { useCustomParam },
  };
}

export function setDefaultLanguage(defaultLanguage) {
  return {
    type: ACTION_TYPES.SET_DEFAULT_LANGUAGE,
    payload: { defaultLanguage },
  };
}

export function setCustomParamToUse(customParam) {
  return {
    type: ACTION_TYPES.SET_CUSTOM_PARAM_TO_USE,
    payload: { customParam },
  };
}

export function appendToAvailableLangauges(languageToAppned) {
  return {
    type: ACTION_TYPES.APPEND_TO_AVAILABLE_LANGUAGES,
    payload: languageToAppned,
  };
}

export function deleteFromAvailableLanguages(languageCode) {
  return {
    type: ACTION_TYPES.DELETE_FROM_AVAILABLE_LANGUAGES,
    payload: { languageCode },
  };
}

export function resetLocalizationState() {
  return {
    type: ACTION_TYPES.RESET_LOCALIZATION_STATE,
  };
}

export function setAllLanguages(allLanguages) {
  return {
    type: ACTION_TYPES.SET_ALL_LANGUAGES,
    payload: { allLanguages },
  };
}

export function setModalLocalizationVersions({ publishedId, draftId, version, isWithToggle }) {
  return {
    type: window.DIG_REDUX.ACTION_TYPES.SET_MODAL_LOCALIZATION_VERSIONS,
    payload: { publishedId, draftId, version, isWithToggle },
  };
}

export function toggleFormMode(formMode) {
  return {
    type: window.DIG_REDUX.ACTION_TYPES.TOGGLE_FORM_MODE,
    payload: { formMode },
  };
}