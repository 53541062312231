import { call, put, takeLatest } from 'redux-saga/effects';
import HttpManager from '../../services/HttpManager';
import { API, PLACEHOLDER } from '../../common/api';
import { setResources } from '../actions/resources.actions';
import { FETCH_RESOURCES, DELETE_RESOURCE } from '../actions/action-types';
import { getNotificationByKampyleId } from '../../common/notification-mapper';
import { pushNotification } from '../../common/utils';

const SUCCESSFUL_DELETE_MESSAGE = 'Resource deleted successfully';

function* fetchResources() {
  try {
    const resources = yield call(HttpManager.get, API.RESOURCES.FETCH);
    yield put(setResources(resources));
  } catch (e) {
    console.error(e);
  }
}

function* deleteResource({ payload: { id } }) {
  try {
    const api = API.RESOURCES.DELETE.replace(PLACEHOLDER.ID, id);
    yield call(HttpManager.delete, api);
    pushNotification({ type: NOTIFICATION_TYPES.SUCCESS, message: SUCCESSFUL_DELETE_MESSAGE });
    yield fetchResources();
  } catch (e) {
    const { kampyleId } = e;
    pushNotification({ type: NOTIFICATION_TYPES.FAILURE, message: getNotificationByKampyleId(kampyleId) });
  }
}

export default function* () {
  yield takeLatest(FETCH_RESOURCES, fetchResources);
  yield takeLatest(DELETE_RESOURCE, deleteResource);
}