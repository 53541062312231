import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.reusableFields, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_REUSABLE_FIELDS:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
}