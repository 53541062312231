import { ROLE_TYPES } from '../../common/constants';
import { KAMPYLE_IDS, NOTIFICATION_TYPES } from '../../common/enums';
import { setFreezeModeModal } from '../../redux/actions/app.actions';
import { fetchResources } from '../../redux/actions/resources.actions';
import { getNotificationByKampyleId } from '../../common/notification-mapper';
import { pushNotification } from '../../common/utils';
import { dispatch, getProfile } from '../../redux/store/store-helper';

export const freezeModeErrorHandler = () => {
  dismissAllModals();
  const { userRole } = getProfile();
  if(userRole){
    if(userRole === ROLE_TYPES.ROLE_ADMIN){
      pushNotification({ type: NOTIFICATION_TYPES.FAILURE , message:getNotificationByKampyleId(KAMPYLE_IDS.FREEZE_MODE) });
    }else{
      dispatch(setFreezeModeModal(true));
    }
  }
};

export const deleteResoureErrorHandler = () => {
  dismissAllModals();
  pushNotification({ type:NOTIFICATION_TYPES.FAILURE, message:getNotificationByKampyleId(KAMPYLE_IDS.RESOURCE_DELETED) });
  dispatch(fetchResources());
};

function dismissAllModals(){
  angular.element(document.body).injector().invoke((ModalHelper) => ModalHelper.dismissAllModals());
}