import {KAMPYLE_IDS} from "./enums";
import {UNKNOWN_ERROR} from "./constants";
import {setNestedProperty} from './utils';

export const PLACEHOLDER = {
  ACTION: '****ACTION****'
};

const notifications = {
  [KAMPYLE_IDS.FOLDER_NAME_ALREADY_EXISTS]: 'A folder with this name already exists',
  [KAMPYLE_IDS.FOLDER_ON_GOING_ACTION]: `${PLACEHOLDER.ACTION} failed, the folder is currently being modified by a different user.`,
  [KAMPYLE_IDS.FOLDER_DOESNT_BELONG_TO_PROPERTY]: 'This folder has already been deleted',
  [KAMPYLE_IDS.LOGIN_BAD_CREDENTIALS]: 'Incorrect email/password',
  [KAMPYLE_IDS.DHH_PACKAGE_IS_BEING_GENERATED]: 'It looks like someone else is creating a package at the moment, Please try again later',
  [KAMPYLE_IDS.PACKAGE_NOT_THE_LATEST]: "It looks like you're trying to download an outdated package, please refresh your screen and try again",
  [KAMPYLE_IDS.PACKAGE_NOT_THE_LATEST_NEED_TO_GENERATE_PACKAGE]: "It looks like a configuration change has been made after this package has been generated, please generate a new package",
  [KAMPYLE_IDS.PACKAGE_NOT_THE_LATEST_NEED_TO_REFRESH]: "It looks like you're package is outdated, please refresh your screen",
  [KAMPYLE_IDS.PACKAGE_ALREADY_CONFIRMED]: "It looks like someone else already confirmed this package, please refresh your screen",
  [KAMPYLE_IDS.INVALID_FILE_TYPE]: "The file that you have uploaded is not a valid image/CSS/zip file",
  [KAMPYLE_IDS.FILE_LARGER_THEN_2000KB]: "The file that you have uploaded is larger than 2MB",
  [KAMPYLE_IDS.FILE_NAME_ALREADY_EXISTS]: "A file with this name already exists",
  [KAMPYLE_IDS.FREEZE_MODE]:"We currently can't handle your request as the command center is in \"Freeze mode\"",
  [KAMPYLE_IDS.RESOURCE_DELETED]:"You're trying to use a file which has been deleted, please choose a different file and save.",
  [KAMPYLE_IDS.RESOURCE_IN_USE]:"This file is in use and cannot be deleted",
  [KAMPYLE_IDS.ILLEGAL_FILE_NAME]: "The filename contains an illegal character, you can use letters, numbers and \"., -, _\"",
  [KAMPYLE_IDS.PACKAGE_GENERATION_FAILED]: "We're sorry but we couldn't generate your package, please try again or reach out to Medallia support",
  [KAMPYLE_IDS.FORMS_REFRESH_ALREADY_IN_PROGRESS]: 'Refresh failed.\nA refresh is already in progress, please wait for it to complete before refreshing again.',
};

setNestedProperty(window,'getNotificationByKampyleId', getNotificationByKampyleId); //for angular

export function getNotificationByKampyleId(kampyleId, fallback = UNKNOWN_ERROR) {
  return notifications[kampyleId] || fallback;
}
