import { takeLatest, put } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import * as ACTION_TYPES from '../actions/action-types';
import * as storeHelper from '../store/store-helper';
import { isFolderInDeleteStatus, getSingleText, pushNotification } from '../../common/utils';
import { setFolders, setSelectedFolder, getFolders, endFoldersProcess, setFolderInProcess, addFolder } from '../actions/folder.actions';
import { getNotificationByKampyleId, PLACEHOLDER as NOTIFICATION_PLACEHOLDER } from '../../common/notification-mapper';
import HttpManager from '../../services/HttpManager';
import { API, PLACEHOLDER } from '../../common/api';
import { FOLDER_ACTIONS, KAMPYLE_IDS, JOB_TYPES } from '../../common/enums';

function getUpdatedSelectedFolder(folders) {
  const selectedFolder = storeHelper.getSelectedFolder();
  return selectedFolder ? storeHelper.getFolderById(selectedFolder.id, folders) : null;
}

function getCandidateSelectedFolder(folders) {
  const selectedFolder = getUpdatedSelectedFolder(folders);
  if (!selectedFolder) {
    return storeHelper.getRootFolder(folders);
  } else if (isFolderInDeleteStatus(selectedFolder)) {
    return selectedFolder;
  }
  return null;
}

function* checkDeletedFoldersStatus(allFolders) {
  const foldersInProcess = storeHelper.getFoldersInProcess();
  let folder;
  const completedFolders = [];

  for (const folderToDelete of (foldersInProcess[JOB_TYPES.DELETE] || [])) {
    folder = storeHelper.getFolderById(folderToDelete.id, allFolders);

    if (!folder) {
      pushNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: `The folder ${folderToDelete.name} has been deleted successfully`,
      });
      completedFolders.push(folderToDelete.id);
    } else if (!isFolderInDeleteStatus(folder)) {
      pushNotification({
        type: NOTIFICATION_TYPES.FAILURE,
        message: 'An error occurred during the folder deletion process',
      });
      completedFolders.push(folderToDelete.id);
    }
  }

  if (completedFolders.length > 0) {
    yield put(endFoldersProcess(JOB_TYPES.DELETE, completedFolders));
  }
}

function* fetchAllFolders() {
  let allFolders = [];
  let foldersUpdated = true;

  try {
    allFolders = yield HttpManager.get(API.FOLDERS.FETCH);
  } catch (e) {
    // TODO: handle
  }

  const currentFolders = storeHelper.getFolders();

  if (allFolders.length === currentFolders.length) {
    const newFoldersString = JSON.stringify(allFolders);
    const currentFoldersString = JSON.stringify(currentFolders);

    foldersUpdated =  newFoldersString !== currentFoldersString;
  }

  if (foldersUpdated) {
    yield put(setFolders(allFolders));
    yield checkDeletedFoldersStatus(allFolders);

    const candidateSelectedFolder = getCandidateSelectedFolder(allFolders);
    if (candidateSelectedFolder) {
      yield put(setSelectedFolder(candidateSelectedFolder));
    }
  }
}

export function* createFolder({ payload: { name } }) {
  try {
    const newFolder = yield HttpManager.post(API.FOLDERS.CREATE, { name });
    yield put(addFolder(newFolder));
    yield put(setSelectedFolder(newFolder));

    pushNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: getSingleText('app.pages.forms.folders.alerts.folderCreatedSuccess'),
    });
  } catch (e) {
    yield handleFolderError(e.kampyleId);
  }
}

function* renameFolder({ payload: { id, name } }) {
  try {
    yield HttpManager.post(API.FOLDERS.RENAME.replace(PLACEHOLDER.ID, id), { name });
    yield put(getFolders());
    const selectedFolder = storeHelper.getSelectedFolder();

    if (selectedFolder.id === id) {
      yield put(setSelectedFolder(Object.assign(selectedFolder, { name })));
    }
  } catch (e) {
    yield handleFolderError(e.kampyleId, FOLDER_ACTIONS.RENAME);
  }
}

function* deleteFolder({ payload: { folderId } }) {
  let errorId = null;
  const folderToDelete = storeHelper.getFolderById(folderId);

  try {
    if (folderToDelete) {
      yield HttpManager.delete(API.FOLDERS.DELETE.replace(PLACEHOLDER.ID, folderId));
    } else {
      errorId = KAMPYLE_IDS.FOLDER_DOESNT_BELONG_TO_PROPERTY;
    }
  } catch (e) {
    errorId = e.kampyleId;
  }

  if (errorId) {
    yield handleFolderError(errorId, FOLDER_ACTIONS.DELETE);
  } else {
    yield put(setFolderInProcess(JOB_TYPES.DELETE, folderToDelete));
    yield put(getFolders());
  }
}

export function handleFolderError(errorId, action, errorMessage) {
  let message;
  if(errorId === KAMPYLE_IDS.FREEZE_MODE) {return;}
  if (errorId === KAMPYLE_IDS.FOLDER_ON_GOING_ACTION) {
    message = getNotificationByKampyleId(errorId).replace(NOTIFICATION_PLACEHOLDER.ACTION, action);
  } else {
    message = errorMessage || getNotificationByKampyleId(errorId) || getSingleText('app.messages.unknownErrorOccurred');
  }

  pushNotification({
    type: NOTIFICATION_TYPES.FAILURE,
    message: message,
  });
}
  
function* startPolling() {
  const pollingInterval = storeHelper.getPollingInterval();
  while (storeHelper.shouldPoll()) {
    yield fetchAllFolders();
    yield delay(pollingInterval);
  }
}


export default function* () {
  yield takeLatest(ACTION_TYPES.FETCH_FOLDERS, fetchAllFolders);
  yield takeLatest(ACTION_TYPES.CREATE_NEW_FOLDER, createFolder);
  yield takeLatest(ACTION_TYPES.RENAME_FOLDER, renameFolder);
  yield takeLatest(ACTION_TYPES.DELETE_FOLDER, deleteFolder);
  yield takeLatest(ACTION_TYPES.START_POLLING, startPolling);
}