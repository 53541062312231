import * as actionTypes from '../actions/action-types';
import HttpManager from '../../services/HttpManager';
import { setProfile } from '../actions/profile.actions';
import { API } from '../../common/api';
import { setVersionId } from '../actions/app.actions';
import { takeLatest, put, select } from 'redux-saga/effects';
import { getNgService } from '../../common/utils';

const APP_VERSION_NUMBER_HEADER_KEY_NAME = 'revisionnumber';

export function* fetchProfile() {
  try {
    const profileResponse = yield HttpManager.getResponse(API.PROFILE.FETCH);
    const profile = profileResponse.data;
    yield put(setVersionId(profileResponse.headers.get(APP_VERSION_NUMBER_HEADER_KEY_NAME)));
    yield put(setProfile(profile));
    const profileState = yield select(getProfileState);
    setProfileData(profileState);
    getNgService((Profile) => { // update Profile for angular
      Profile.setProfile(profileState);
    });
  } catch (e) {
    console.error('Failed to fetch profile');
  }
}

//used for M@M account embed in the command-center
function setProfileData(profileState) {
  if (profileState && profileState.isLoggedIn) {
    const type = getProductType(profileState.propertyType);
    // This data will potentially be used by other services (M@M, analytics etc...)
    window.profileData = {
      name: profileState.userName, // User's full name
      email: profileState.email, // User's email address
      user_id: profileState.email, // User's email address is used for user_id
      role: getProfileRole(profileState.userRole), // User’s role in the digital app
      product: 'Digital', // Static value
      isV2formsUpgradeAvailable: (!!profileState.provisioning[DigProvisions.SELF_SERVICE_V2_MIGRATION] && !profileState.provisioning[DigProvisions.LIVE_FORM_V2]),
      isDraftsEnabled: !!profileState.provisioning[DigProvisions.ENABLE_SURVEY_DRAFTS],
      isApproverRoleEnabled: !!profileState.provisioning[DigProvisions.ENABLE_APPROVER_ROLE],
      isNewCCEnabled: !!profileState.accountSettings && profileState.accountSettings.IS_NEW_COMMAND_CENTER,
      company: {
        company_id: profileState.accountName, // The account name in Digital
        name: profileState.accountName, // The unique identifier for the customer
        account_id: profileState.accountId, // If available, the current version of the app
        property_id: profileState.propertyId, // The unique property identifier for the customer
        property_name: profileState.propertyName, //The name of the property that matched the property ID
        type, // Determines the type of the digital property
        web_offering_type: getWebOfferingType(profileState.provisioning),
        url: window.document.location.href,
        ...(type === 'In-App' ? { in_app_offering_type: profileState.propertyType === 'mobileIOS' ? 'iOS' : 'Android' } : {}),
      },
    };
  }

  getNgService((Common) => {
    Common.startInviteTargeting();
  });
}

export function getWebOfferingType( provisions ) {
  if (!provisions.onPremHybrid) {
    return 'Cloud';
  } else {
    if (provisions.dynamicHybridHosting) {
      return 'Dynamic Hybrid Hosting';
    }
  }
  return 'Hybrid Hosting';
}

function getProductType(type) {
  switch (type) {
    case 'website':
      return 'Web';
    case 'mobileIOS':
    case 'mobileAndroid':
      return 'In-App';
    default:
      return 'Anywhere';
  }
}

function getProfileRole(role) {
  const ROLES_MAPPING = {
    ROLE_VIEWER: 'Analyst',
    ROLE_USER: 'CX Manager',
    ROLE_USER_GROUP_MANAGER: 'CX Group Manager',
    ROLE_ADMIN: 'System Administrator',
    ROLE_SUPER_ADMIN: 'Super Admin',
  };

  return ROLES_MAPPING[role] || '';
}

export const getProfileState = (state) => state.profile;

export default function* () {
  yield takeLatest(actionTypes.GET_PROFILE, fetchProfile);
}