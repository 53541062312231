import * as ACTION_TYPES from './action-types';

export function setInboxState(inboxState) {
  return {
    type: ACTION_TYPES.SET_INBOX_STATE,
    payload: inboxState,
  };
}

export function logout() {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
}

export function setUniqueSessionRequestID(uniqueSessionRequestID) {
  return {
    type: ACTION_TYPES.SET_UNIQUE_SESSION_REQUEST_ID,
    payload: uniqueSessionRequestID,
  };
}

export function setPropertyId(propertyId) {
  return {
    type: ACTION_TYPES.SET_PROPERTY_ID,
    payload: { propertyId },
  };
}

export function setErrorMessage(message) {
  return {
    type: ACTION_TYPES.SET_ERROR_MESSAGE,
    payload: { message },
  };
}

export function setSuccessMessage(message) {
  return {
    type: ACTION_TYPES.SET_SUCCESS_MESSAGE,
    payload: { message },
  };
}

export function setFreezeModeModal(isShown){
  return{
    type: ACTION_TYPES.SET_FREEZEMODE_MODAL,
    isShown,
  };
}

export function setVersionId(versionId) {
  return {
    type: ACTION_TYPES.SET_APP_VERSION_ID,
    payload: { versionId },
  };
}

export function fetchEnvConfig() {
  return {
    type: ACTION_TYPES.FETCH_ENV_CONFIG,
  };
}

export function setEnvConfig(config) {
  return {
    type: ACTION_TYPES.SET_ENV_CONFIG,
    payload: { config },
  };
}

export function showSurveyUpgradeModal() {
  return {
    type: ACTION_TYPES.SHOW_SURVEY_UPGRADE_MODAL,
  };
}

export function hideSurveyUpgradeModal() {
  return {
    type: ACTION_TYPES.HIDE_SURVEY_UPGRADE_MODAL,
  };
}
