import { FETCH_ALL_LANGUAGES } from '../actions/action-types';
import { takeEvery, put } from 'redux-saga/effects';
import HttpManager from '../../services/HttpManager';
import { API } from '../../common/api';
import { setAllLanguages } from '../actions/localization.actions';

function* fetchAllLanguages() {
  let allLanguages = [];
  try {
    allLanguages = yield HttpManager.get(API.LOCALIZATION.FETCH_ALL_LANGUAGES);
  } catch (e) {
    // TODO: handle
  }
  yield put(setAllLanguages(allLanguages));
}

export default function* () {
  yield takeEvery(FETCH_ALL_LANGUAGES, fetchAllLanguages);
}