import * as ACTION_TYPES from '../actions/action-types';
import { initialState } from '../store/initial-state';

export default function(state = initialState.folders, action) {
  switch (action.type) {
    case  ACTION_TYPES.SET_FOLDERS:
      const { allFolders } = action.payload;
      return {
        ...state,
        allFolders,
      };
    case ACTION_TYPES.SET_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolder: action.payload.selectedFolder,
      };
    case ACTION_TYPES.ADD_NEW_FOLDER:
      return {
        ...state,
        allFolders: [...state.allFolders, action.payload.newFolder],
      };

    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState.folders,
      };

    case ACTION_TYPES.START_POLLING:
      return {
        ...state,
        isPolling: true,
      };

    case ACTION_TYPES.STOP_POLLING:
      return {
        ...state,
        isPolling: false,
      };

    case ACTION_TYPES.SET_FOLDER_IN_PROCESS: {
      const { folder, jobType } = action.payload;
      const prevJobState = state.foldersInProcess[jobType] || [];
      const nextJobState = { [jobType]: [...prevJobState, folder] };
      const updatedFoldersInProcess = Object.assign({}, state.foldersInProcess, nextJobState);

      return {
        ...state,
        foldersInProcess: updatedFoldersInProcess,
      };
    }
    case ACTION_TYPES.END_FOLDERS_PROCESS: {
      const { jobType, completedFolders } = action.payload;
      const prevJobState = state.foldersInProcess[jobType] || [];
      const filteredFolders = prevJobState.filter((folder) => !completedFolders.includes(folder.id));
      const nextJobState = { [jobType]: filteredFolders };
      const updatedFoldersInProcess = Object.assign({}, state.foldersInProcess, nextJobState);

      return {
        ...state,
        foldersInProcess: updatedFoldersInProcess,
      };
    }
  }
  return state;
}