import * as ACTION_TYPES from './action-types';

export function startRefreshPolling(allSelectedForms, areAllItemsChecked) {
  return {
    type: ACTION_TYPES.START_REFRESH_POLLING,
    payload: { allSelectedForms, areAllItemsChecked },
  };
}

export function stopRefreshPolling() {
  return {
    type: ACTION_TYPES.STOP_REFRESH_POLLING,
  };
}
